import * as React from 'react';

interface NoContentProps {
  className?: string;
  isDark?: boolean;
}

const NoContent: React.FC<NoContentProps> = ({ isDark, className }) =>
  isDark ? (
    <svg
      width={222}
      height={168}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112 153.521c-60.75 0-110 3.122-110 6.99 0 3.854 49.25 6.989 110 6.989 60.751 0 110-3.121 110-6.989.013-3.868-49.237-6.99-110-6.99Z"
        fill="url(#a)"
      />
      <g clipPath="url(#b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M129.284 27.836H74.795c-5.02 0-9.081 4.116-9.081 9.19v114.231c0 5.075 4.061 9.191 9.081 9.191h54.489c5.02 0 9.081-4.116 9.081-9.191V37.027c0-5.075-4.061-9.191-9.081-9.191Z"
          fill="url(#c)"
        />
        <path
          d="M134.313 27.836h-56.65a8 8 0 0 0-8 8v116.668a8 8 0 0 0 8 8h56.65a8 8 0 0 0 8-8V35.836a8 8 0 0 0-8-8Z"
          fill="url(#d)"
        />
        <path d="M138.534 34.377H73.498V154.81h65.036V34.376Z" fill="#fff" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.535 34.377v8.513H73.498l-.042-8.42c2.765-.094 2.242-.093 4.893-.093H138.535Z"
          fill="url(#e)"
        />
        <path
          opacity={0.39}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.573 30.373h13.199c.395 0 .677.338.677.677a.686.686 0 0 1-.677.676h-13.199a.686.686 0 0 1-.677-.676c-.057-.395.282-.677.677-.677Z"
          fill="#5D83C8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M128.777 37.703H111.46c-.621 0-1.185.507-1.185 1.184 0 .62.508 1.184 1.185 1.184h17.317c.62 0 1.184-.508 1.184-1.184-.056-.62-.564-1.184-1.184-1.184ZM133.965 37.196c-.902 0-1.579.733-1.579 1.578 0 .846.733 1.579 1.579 1.579.903 0 1.58-.733 1.58-1.579s-.677-1.578-1.58-1.578Z"
          fill="#F1F4F9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.688 36.688a2.082 2.082 0 0 0-2.087 2.086c0 1.184.959 2.086 2.087 2.086a2.082 2.082 0 0 0 2.087-2.086c.056-1.184-.903-2.086-2.087-2.086Z"
          fill="#B1DFF7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.97 42.383h-.394a.385.385 0 0 0-.395.395v14.208c0 .225.169.395.395.395h.394V42.383Z"
          fill="#1B83E1"
        />
        <path d="M134.586 61.778H77.897v45.388h56.689V61.778Z" fill="#F1F4F9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.195 124.532h54.544c.846 0 1.58.564 1.58 1.297 0 .676-.677 1.297-1.58 1.297H79.195c-.846 0-1.58-.564-1.58-1.297.057-.733.734-1.297 1.58-1.297ZM79.195 133.497h54.544c.846 0 1.58.564 1.58 1.297 0 .676-.677 1.296-1.58 1.296H79.195c-.846 0-1.58-.563-1.58-1.296.057-.733.734-1.297 1.58-1.297ZM79.195 142.518h54.544c.846 0 1.58.564 1.58 1.297 0 .676-.677 1.297-1.58 1.297H79.195c-.846 0-1.58-.564-1.58-1.297.057-.733.734-1.297 1.58-1.297Z"
          fill="#F1F4F9"
        />
        <path d="M121.161 74.182H77.897v32.984h43.264V74.182Z" fill="#3F73E3" />
        <path d="M131.935 85.29H103.28v21.876h28.655V85.29Z" fill="#5384EE" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.581 68.826a5.356 5.356 0 0 1 5.359 5.356 5.355 5.355 0 0 1-5.359 5.357 5.355 5.355 0 0 1-5.358-5.357 5.355 5.355 0 0 1 5.358-5.356Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.195 115.736h54.544c.846 0 1.58.564 1.58 1.297 0 .677-.677 1.297-1.58 1.297H79.195c-.846 0-1.58-.564-1.58-1.297.057-.733.734-1.297 1.58-1.297Z"
          fill="#F1F4F9"
        />
        <path
          d="M88.671 47.57h-11v9.754h11V47.57ZM103.957 47.57H92.958v9.754h10.999V47.57ZM119.3 47.57h-10.999v9.754H119.3V47.57ZM134.642 47.57h-10.999v9.754h10.999V47.57Z"
          fill="#F1F4F9"
        />
      </g>
      <g clipPath="url(#f)">
        <path
          d="M157.058 155.517s-6.57-3.175-5.601-8.128c.968-4.911 6.317-4.403 5.601-8.298-.716-3.895-4.254-9.949-.8-14.351 3.37-4.445 9.056-1.778 9.772-8.637.589-5.503 2.99-9.398 7.623-8.255 4.296 1.059 7.497 4.572 7.582 12.997.084 8.975 6.022 6.435 6.107 13.039.084 6.646-4.928 6.646-6.866 11.092-.926 2.116 1.475 9.525-5.601 10.498h-17.817v.043Z"
          fill="#A6AEB7"
          fillOpacity={0.1}
        />
        <path
          d="M172.937 111.404s0 .17.043.424c.084.889.21 3.048.21 6.011 0 8.763-1.306 24.766-9.519 37.551 0 .042-.042.042-.042.084h.843c8.171-12.912 9.476-28.829 9.476-37.593 0-4.021-.252-6.561-.252-6.561-.043-.212-.211-.381-.38-.339-.252.042-.379.212-.379.423Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M162.913 155.517s1.011-6.097 4.886-8.552c3.917-2.498 7.876-4.445 7.455-8.425-.421-3.979.632-8.72 6.865-8.805 3.833-.042 2.401-4.361 6.318-5.207 2.359-.508 3.959 2.116 4.254 4.699.337 2.963-1.221 5.842.59 8.255 2.358 3.133 3.285 8.933-1.727 10.584-5.012 1.651-.548 6.688-7.371 7.451H162.913Z"
          fill="#606060"
        />
        <path
          d="M188.142 127.406c-.126 3.895-1.769 9.399-4.506 14.648-2.78 5.25-6.697 10.287-11.372 13.251-.085.042-.127.127-.169.169h1.222c4.548-3.048 8.255-7.959 10.95-13.039 2.822-5.376 4.465-10.922 4.591-14.986 0-.212-.126-.381-.337-.381-.21-.043-.379.127-.379.338Z"
          fill="#fff"
        />
      </g>
      <path
        d="M41.096 67.113s9.75 12.447-1.375 25.104l-4.184-.39s8.048-12.886 4.465-23.164l-5.924.434-3.275-2.13 10.293.146Z"
        fill="#FED2A4"
      />
      <path
        d="M42.126 89.066c.032-.038-1.728-5.295-1.728-5.295l-2.52.23-.985 5.964s3.947.791 5.233-.9Z"
        fill="url(#g)"
      />
      <path
        d="M38.48 75.484c.166.102 5.38 2.252 4.26 7.65a1.016 1.016 0 0 1-.576.72c-.767.338-2.412.925-4.382.721-2.731-.274-4.523-5.027-4.446-6.412.077-1.378 3.48-3.713 5.144-2.68Z"
        fill="#FFE5C2"
      />
      <g filter="url(#h)">
        <path
          d="M77.279 19.042 16.754 18.5a2.115 2.115 0 0 0-2.143 2.13l.262 46.324a2.116 2.116 0 0 0 2.188 2.105c10.94-.325 59.533-1.76 61.676-1.671 2.118.089.966-37.117.653-46.298a2.125 2.125 0 0 0-2.111-2.048Z"
          fill="#606060"
        />
      </g>
      <path
        d="M16.997 67.292h.013c8.7-.262 52.51-1.557 60.544-1.671h.09l.013-.09c.76-4.268.742-21.69-.039-44.383a.352.352 0 0 0-.352-.338l-60.53-.543a.381.381 0 0 0-.257.102.337.337 0 0 0-.102.25l.262 46.322a.36.36 0 0 0 .358.351Zm60.461-1.882c-8.265.121-51.786 1.41-60.454 1.665h-.007c-.07 0-.128-.064-.128-.134l-.262-46.323a.13.13 0 0 1 .039-.095.135.135 0 0 1 .102-.039l60.518.542c.07 0 .135.058.135.128.774 22.533.793 39.854.057 44.256Z"
        fill="#fff"
      />
      <path
        d="M69.973 61.36H30.297c-1.234 0-2.002-1.34-1.375-2.4l15.705-26.628c.653-1.11 2.29-1.027 2.828.146l7.587 16.575a1.6 1.6 0 0 0 2.533.51l3.071-2.807a1.6 1.6 0 0 1 2.393.268l8.246 11.834c.742 1.06-.02 2.501-1.312 2.501ZM29.405 37.037a5.69 5.69 0 0 1-4.093-6.936c.79-3.042 3.904-4.87 6.955-4.081a5.69 5.69 0 0 1 4.093 6.936c-.79 3.042-3.904 4.87-6.955 4.081Z"
        fill="#fff"
      />
      <path
        d="M35.76 128.052s-7.9 17.167-12.039 24.274c0 0-.825 4.473 0 5.882l.704.313-3.276-.096s-.288-6.016-.064-6.354c.23-.338.685-.312.685-.312s1.4-11.088 5.47-13.455l3.934-13.538 4.587 3.286Z"
        fill="#FFE5C2"
      />
      <path
        d="M25.818 159.364c.007.076-2.559.044-3.947.019a1.106 1.106 0 0 1-1.062-.868l-1.049-5.506a.981.981 0 0 1 .755-1.148l.588-.134s.839 1.142.576 2.22c-.262 1.078-.499 3.866.167 4.192.665.325 2.578.382 2.578.382s1.35.134 1.394.843ZM43.244 159.459c.256.147.154.542-.147.536-2.328-.025-7.542-.096-8.01-.287-.6-.249.218-2.392 1.075-3.12l4.747 1.544 2.335 1.327Z"
        fill="#112B46"
      />
      <path
        d="M44.716 126.955s-5.105 24.625-6.551 29.429l3.416 2.138s-1.714-.294-2.981-.383c-1.267-.09-2.437-1.544-2.437-1.544l.41-.651s1.279-24.491.965-27.267l7.178-1.722ZM57.388 69.525s.704 12.81-10.99 19.356l2.815 6.405S59.8 88.9 59.41 71.484a2.035 2.035 0 0 1 .499-1.365l2.252-2.603a4.008 4.008 0 0 0 .857-3.605l-.294-1.18a.615.615 0 0 0-.902-.383l-.237.134a4.106 4.106 0 0 0-1.695 1.882l-1.107 2.463c-.416.918-1.388.855-2.399.772l-1.612.382 2.616 1.544Z"
        fill="#FFE5C2"
      />
      <path
        d="M46.801 87.918c-1.957.542-8.585 2.277-12.724 2.207l2.54 14.297-.205 2.335 8.285-.039s2.328-5.397 4.17-8.089c.532-.778.135-1.703-.166-2.597l.416-.274c.422-.28.601-.804.448-1.282l-2.015-6.157a.612.612 0 0 0-.749-.401Z"
        fill="url(#i)"
      />
      <path
        d="m45.369 105.493-.16 1.41s2.341 6.992-1.17 23.318c0 0-7.607 2.15-13.857-2.992 0 0 1.766-15.413 2.245-16.128 2.4-3.572 3.999-4.351 3.999-4.351l.07-1.544c0 .007 6.679 1.206 8.873.287Z"
        fill="url(#j)"
      />
      <path
        d="M36.893 73.391c.134.02 3.013 1.014 1.586 3.094-1.426 2.08-1.964 3.305-1.286 4.217.614.83 1.977.357 1.855 2.086a.96.96 0 0 0 .416.874c1.286.868 4.415 3.535 1.312 7.33a.964.964 0 0 0-.109 1.085c.908 1.627 2.815 6.386-4.088 9.021-6.826 2.609-9.289-1.837-9.98-3.617a.954.954 0 0 0-.94-.6c-.748.038-1.81-.09-1.766-1.091.032-.606.41-1.148.858-1.589.441-.433.358-1.186-.186-1.492-.41-.23-.768-.594-.921-1.155-.416-1.531.41-6.1 4.804-6.871.365-.064.749-.408.806-.772v-.02c.058-.356-.064-.72-.352-.93-.703-.51-1.599-1.544-.87-3.203.646-1.467 1.894-1.965 2.815-2.124a.962.962 0 0 0 .787-1.142c-.313-1.455-.038-3.796 5.259-3.101Z"
        fill="url(#k)"
      />
      <defs>
        <linearGradient
          id="a"
          x1={112}
          y1={153.521}
          x2={112}
          y2={167.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={102.039}
          y1={27.836}
          x2={102.039}
          y2={160.448}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7899D5" />
          <stop offset={1} stopColor="#618AB3" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={105.988}
          y1={27.836}
          x2={105.988}
          y2={160.504}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0BBEC" />
          <stop offset={1} stopColor="#507CA8" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={105.995}
          y1={34.377}
          x2={105.534}
          y2={57.761}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8EEF8" />
          <stop offset={1} stopColor="#CDD9EB" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={42.126}
          y1={86.963}
          x2={36.894}
          y2={86.963}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset={1} stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={50.346}
          y1={90.771}
          x2={44.534}
          y2={109.218}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={45.963}
          y1={117.949}
          x2={30.168}
          y2={117.949}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="k"
          x1={32.797}
          y1={73.335}
          x2={32.797}
          y2={101.775}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <clipPath id="b">
          <path
            fill="#fff"
            transform="translate(65.714 27.836)"
            d="M0 0h76.656v132.668H0z"
          />
        </clipPath>
        <clipPath id="f">
          <path
            fill="#fff"
            transform="matrix(-1 0 0 1 195.134 107.636)"
            d="M0 0h43.803v47.88H0z"
          />
        </clipPath>
        <filter
          id="h"
          x={0.61}
          y={0.5}
          width={105.374}
          height={90.56}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={6} dy={2} />
          <feGaussianBlur stdDeviation={10} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4230_67569"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="effect1_dropShadow_4230_67569"
            result="effect2_dropShadow_4230_67569"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_4230_67569"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg
      width={220}
      height={150}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110 135.521c-60.75 0-110 3.122-110 6.99 0 3.854 49.25 6.989 110 6.989 60.751 0 110-3.121 110-6.989.013-3.868-49.237-6.99-110-6.99Z"
        fill="url(#a)"
      />
      <g clipPath="url(#b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M127.284 9.836H72.795c-5.02 0-9.081 4.116-9.081 9.19v114.231c0 5.075 4.061 9.191 9.081 9.191h54.489c5.02 0 9.081-4.116 9.081-9.191V19.027c0-5.075-4.061-9.191-9.081-9.191Z"
          fill="url(#c)"
        />
        <path
          d="M132.313 9.836h-56.65a8 8 0 0 0-8 8v116.668a8 8 0 0 0 8 8h56.65a8 8 0 0 0 8-8V17.836a8 8 0 0 0-8-8Z"
          fill="url(#d)"
        />
        <path d="M136.534 16.377H71.498V136.81h65.036V16.376Z" fill="#fff" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.535 16.377v8.513H71.498l-.042-8.42c2.765-.094 2.242-.093 4.893-.093H136.535Z"
          fill="url(#e)"
        />
        <path
          opacity={0.39}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.573 12.373h13.199c.395 0 .677.338.677.677a.686.686 0 0 1-.677.676h-13.2a.686.686 0 0 1-.676-.676c-.057-.395.282-.677.677-.677Z"
          fill="#5D83C8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.777 19.703H109.46c-.621 0-1.185.507-1.185 1.184 0 .62.508 1.184 1.185 1.184h17.317c.62 0 1.184-.508 1.184-1.184-.056-.62-.564-1.184-1.184-1.184ZM131.965 19.196c-.902 0-1.579.732-1.579 1.578 0 .846.733 1.579 1.579 1.579.903 0 1.58-.733 1.58-1.579s-.677-1.578-1.58-1.578Z"
          fill="#F1F4F9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.688 18.688a2.082 2.082 0 0 0-2.087 2.086c0 1.184.959 2.086 2.087 2.086a2.082 2.082 0 0 0 2.087-2.086c.056-1.184-.903-2.086-2.087-2.086Z"
          fill="#B1DFF7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.97 24.383h-.394a.385.385 0 0 0-.395.395v14.208c0 .225.169.395.395.395h.394V24.383Z"
          fill="#1B83E1"
        />
        <path d="M132.586 43.778H75.897v45.388h56.689V43.778Z" fill="#F1F4F9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.195 106.532h54.544c.846 0 1.58.564 1.58 1.297 0 .676-.677 1.297-1.58 1.297H77.195c-.846 0-1.58-.564-1.58-1.297.057-.733.734-1.297 1.58-1.297ZM77.195 115.497h54.544c.846 0 1.58.564 1.58 1.297 0 .676-.677 1.296-1.58 1.296H77.195c-.846 0-1.58-.563-1.58-1.296.057-.733.734-1.297 1.58-1.297ZM77.195 124.518h54.544c.846 0 1.58.564 1.58 1.297 0 .676-.677 1.297-1.58 1.297H77.195c-.846 0-1.58-.564-1.58-1.297.057-.733.734-1.297 1.58-1.297Z"
          fill="#F1F4F9"
        />
        <path d="M119.161 56.182H75.897v32.984h43.264V56.182Z" fill="#3F73E3" />
        <path d="M129.935 67.29H101.28v21.876h28.655V67.29Z" fill="#5384EE" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M117.581 50.826a5.356 5.356 0 0 1 5.359 5.356 5.355 5.355 0 0 1-5.359 5.357 5.355 5.355 0 0 1-5.358-5.357 5.355 5.355 0 0 1 5.358-5.356Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.195 97.736h54.544c.846 0 1.58.564 1.58 1.297 0 .677-.677 1.297-1.58 1.297H77.195c-.846 0-1.58-.564-1.58-1.297.057-.733.734-1.297 1.58-1.297Z"
          fill="#F1F4F9"
        />
        <path
          d="M86.671 29.57h-11v9.754h11V29.57ZM101.957 29.57H90.958v9.754h10.999V29.57ZM117.3 29.57h-10.999v9.754H117.3V29.57ZM132.642 29.57h-10.999v9.754h10.999V29.57Z"
          fill="#F1F4F9"
        />
      </g>
      <g clipPath="url(#f)">
        <path
          d="M155.057 137.517s-6.57-3.175-5.601-8.128c.968-4.911 6.317-4.403 5.601-8.298-.716-3.895-4.254-9.949-.8-14.351 3.37-4.445 9.056-1.778 9.772-8.636.589-5.504 2.99-9.399 7.623-8.256 4.296 1.059 7.497 4.572 7.582 12.997.084 8.975 6.023 6.435 6.107 13.039.084 6.646-4.928 6.646-6.866 11.092-.926 2.116 1.475 9.525-5.601 10.498h-17.817v.043Z"
          fill="url(#g)"
        />
        <path
          d="M170.936 93.404s0 .17.043.424c.084.889.21 3.048.21 6.011 0 8.763-1.306 24.766-9.519 37.551 0 .042-.042.042-.042.084h.843c8.171-12.912 9.476-28.829 9.476-37.593 0-4.021-.252-6.561-.252-6.561-.043-.212-.211-.381-.38-.34-.252.043-.379.213-.379.424Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M160.913 137.517s1.011-6.097 4.886-8.552c3.917-2.498 7.876-4.445 7.455-8.425-.421-3.979.632-8.72 6.865-8.805 3.833-.042 2.401-4.361 6.318-5.207 2.359-.508 3.959 2.116 4.254 4.699.337 2.963-1.221 5.842.59 8.255 2.358 3.133 3.285 8.933-1.727 10.584-5.012 1.651-.548 6.688-7.371 7.451H160.913Z"
          fill="url(#h)"
        />
        <path
          d="M186.141 109.406c-.126 3.895-1.769 9.399-4.506 14.648-2.78 5.25-6.697 10.287-11.372 13.251-.085.042-.127.127-.169.169h1.222c4.548-3.048 8.255-7.959 10.95-13.039 2.822-5.376 4.465-10.922 4.591-14.986 0-.212-.126-.381-.337-.381-.21-.043-.379.127-.379.338Z"
          fill="#fff"
        />
      </g>
      <path
        d="M39.096 49.113s9.75 12.447-1.375 25.104l-4.184-.39s8.048-12.886 4.465-23.164l-5.924.434-3.275-2.13 10.293.146Z"
        fill="#FED2A4"
      />
      <path
        d="M40.126 71.066c.032-.038-1.728-5.295-1.728-5.295l-2.52.23-.985 5.964s3.947.791 5.233-.9Z"
        fill="url(#i)"
      />
      <path
        d="M36.48 57.484c.166.102 5.38 2.252 4.26 7.65a1.016 1.016 0 0 1-.576.72c-.767.338-2.412.925-4.382.721-2.731-.274-4.523-5.027-4.446-6.412.077-1.378 3.48-3.713 5.144-2.68Z"
        fill="#FFE5C2"
      />
      <path
        d="M75.279 1.042 14.754.5a2.115 2.115 0 0 0-2.143 2.13l.262 46.324a2.116 2.116 0 0 0 2.188 2.105c10.94-.325 59.533-1.76 61.676-1.671 2.118.089.966-37.117.653-46.298a2.125 2.125 0 0 0-2.111-2.048Z"
        fill="#A1C2FF"
      />
      <path
        d="M14.998 49.292h.012c8.7-.262 52.51-1.557 60.544-1.672h.09l.013-.089c.76-4.268.742-21.69-.039-44.383a.352.352 0 0 0-.352-.338l-60.53-.543a.381.381 0 0 0-.257.102.337.337 0 0 0-.102.25l.262 46.322a.36.36 0 0 0 .359.351Zm60.46-1.882c-8.265.121-51.786 1.41-60.454 1.665h-.006c-.07 0-.128-.064-.128-.134l-.263-46.323a.13.13 0 0 1 .039-.095.135.135 0 0 1 .102-.039l60.518.543c.07 0 .135.057.135.127.774 22.533.793 39.854.057 44.256Z"
        fill="#fff"
      />
      <path
        d="M67.973 43.36H28.297c-1.234 0-2.002-1.34-1.375-2.4l15.705-26.628c.653-1.11 2.29-1.027 2.828.146l7.587 16.575a1.6 1.6 0 0 0 2.533.51l3.071-2.807a1.6 1.6 0 0 1 2.393.268l8.246 11.834c.742 1.06-.02 2.501-1.312 2.501ZM27.405 19.037a5.69 5.69 0 0 1-4.093-6.936c.79-3.042 3.904-4.87 6.955-4.081a5.69 5.69 0 0 1 4.093 6.936c-.79 3.042-3.904 4.87-6.955 4.081Z"
        fill="#fff"
      />
      <path
        d="M33.76 110.052s-7.9 17.167-12.039 24.274c0 0-.825 4.473 0 5.882l.704.313-3.276-.096s-.288-6.016-.064-6.354c.23-.338.685-.312.685-.312s1.4-11.088 5.47-13.455l3.934-13.538 4.587 3.286Z"
        fill="#FFE5C2"
      />
      <path
        d="M23.818 141.364c.007.076-2.559.044-3.947.019a1.106 1.106 0 0 1-1.062-.868l-1.049-5.506a.981.981 0 0 1 .755-1.148l.588-.134s.839 1.142.576 2.22c-.262 1.078-.499 3.866.167 4.192.665.325 2.578.382 2.578.382s1.35.134 1.394.843ZM41.244 141.459c.256.147.154.542-.147.536-2.328-.025-7.542-.096-8.01-.287-.6-.249.218-2.392 1.075-3.12l4.747 1.544 2.335 1.327Z"
        fill="#112B46"
      />
      <path
        d="M42.716 108.955s-5.105 24.625-6.551 29.429l3.416 2.138s-1.714-.294-2.981-.383c-1.267-.09-2.437-1.544-2.437-1.544l.41-.651s1.279-24.491.965-27.267l7.178-1.722ZM55.388 51.525s.704 12.81-10.99 19.356l2.815 6.405S57.8 70.9 57.41 53.484a2.035 2.035 0 0 1 .499-1.366l2.252-2.602a4.008 4.008 0 0 0 .857-3.605l-.294-1.18a.615.615 0 0 0-.902-.383l-.237.134a4.106 4.106 0 0 0-1.695 1.882l-1.107 2.463c-.416.918-1.388.855-2.399.772l-1.612.382 2.616 1.544Z"
        fill="#FFE5C2"
      />
      <path
        d="M44.801 69.918c-1.957.542-8.585 2.277-12.724 2.207l2.54 14.297-.205 2.335 8.285-.039s2.328-5.397 4.17-8.089c.532-.778.135-1.703-.166-2.597l.416-.274c.422-.28.601-.804.448-1.282l-2.015-6.157a.612.612 0 0 0-.749-.401Z"
        fill="url(#j)"
      />
      <path
        d="m43.369 87.493-.16 1.41s2.341 6.992-1.17 23.318c0 0-7.607 2.15-13.857-2.992 0 0 1.766-15.413 2.245-16.128 2.4-3.573 3.999-4.35 3.999-4.35l.07-1.545c0 .007 6.679 1.206 8.873.287Z"
        fill="url(#k)"
      />
      <path
        d="M34.893 55.391c.134.02 3.013 1.014 1.586 3.094-1.426 2.08-1.964 3.305-1.286 4.217.614.83 1.977.357 1.855 2.086a.96.96 0 0 0 .416.874c1.286.868 4.415 3.535 1.312 7.33a.964.964 0 0 0-.109 1.085c.908 1.627 2.815 6.386-4.088 9.02-6.826 2.61-9.289-1.836-9.98-3.616a.954.954 0 0 0-.94-.6c-.748.038-1.81-.09-1.766-1.091.032-.606.41-1.148.858-1.589.441-.433.358-1.186-.186-1.492-.41-.23-.768-.594-.921-1.155-.416-1.531.41-6.1 4.804-6.871.365-.064.749-.408.806-.772v-.02c.058-.356-.064-.72-.352-.93-.703-.51-1.599-1.544-.87-3.203.646-1.467 1.894-1.965 2.815-2.125a.962.962 0 0 0 .787-1.142c-.313-1.454-.038-3.795 5.259-3.1Z"
        fill="url(#l)"
      />
      <defs>
        <linearGradient
          id="a"
          x1={110}
          y1={135.549}
          x2={110}
          y2={149.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#DCE9FC" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="c"
          x1={100.039}
          y1={9.836}
          x2={100.039}
          y2={142.448}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7899D5" />
          <stop offset={1} stopColor="#618AB3" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={103.988}
          y1={9.836}
          x2={103.988}
          y2={142.504}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0BBEC" />
          <stop offset={1} stopColor="#507CA8" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={103.995}
          y1={16.377}
          x2={103.534}
          y2={39.761}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8EEF8" />
          <stop offset={1} stopColor="#CDD9EB" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={167.35}
          y1={89.648}
          x2={167.35}
          y2={137.517}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3E3FF" />
          <stop offset={1} stopColor="#BDD8F3" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={177.051}
          y1={106.464}
          x2={177.051}
          y2={137.517}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF2FF" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={40.126}
          y1={68.963}
          x2={34.894}
          y2={68.963}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset={1} stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={48.346}
          y1={72.771}
          x2={42.534}
          y2={91.218}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="k"
          x1={43.963}
          y1={99.949}
          x2={28.168}
          y2={99.949}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="l"
          x1={30.797}
          y1={55.335}
          x2={30.797}
          y2={83.775}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <clipPath id="b">
          <path
            fill="#fff"
            transform="translate(63.714 9.836)"
            d="M0 0h76.656v132.668H0z"
          />
        </clipPath>
        <clipPath id="f">
          <path
            fill="#fff"
            transform="matrix(-1 0 0 1 193.133 89.636)"
            d="M0 0h43.803v47.88H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );

export default NoContent;
