import { jobsApi, QueryKeys, useReactQuery } from '@lobox/utils';
import type { IJob } from '@lobox/utils';

export function useGetPostAttachmentData(
  id: number,
  variant?: 'job' | 'post' | 'page'
): IJob | undefined {
  const queryKey = [String(id), QueryKeys.currentEntityId];
  const { data: dataJob } = useReactQuery<IJob>({
    action: {
      key: queryKey,
      apiFunc: jobsApi.getJobDetails,
      params: { id },
    },
    config: {
      enabled: !!id && variant === 'job',
      refetchOnMount: false,
      staleTime: 1000 * 60 * 10,
    },
  });
  return dataJob;
}
