import React, { useState } from 'react';
import {
  isPropsChanged,
  useGetLinkMetaData,
  useTranslation,
} from '@lobox/utils';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Image from '@lobox/uikit/Image';
import Skeleton from '@lobox/uikit/Skeleton';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import env from '@shared/constants/env';
import classes from './SearchPosts.ogLink.module.scss';
import useCssVariables from '@shared/hooks/useCssVariables';

interface LinkPreviewProps {
  url: string;
  fallback?: () => React.FC;
  skeletonClassName?: string;
  variant?: 'feed' | 'comment';
  isCloseable?: boolean;
  className?: string;
}

const LinkPreview: React.FC<LinkPreviewProps> = ({
  skeletonClassName,
  url,
  fallback,
  variant = 'feed',
  isCloseable,
  className,
}) => {
  const { t } = useTranslation();
  const isComment = variant === 'comment';
  const { isTabletAndLess } = useMedia();
  const size = isComment
    ? isTabletAndLess
      ? 64
      : 120
    : isTabletAndLess
      ? 280
      : 485;
  const [isClosed, toggle] = useState(false);

  const { data, isLoading, error } = useGetLinkMetaData({
    url,
    linkPreviewUrl: env.reactLinkPreview,
  });

  const cssVars = useCssVariables({
    scope: classes.searchPostScope,
    variables: { sizeSearchImg: `${size}px` },
  });

  if (isClosed) {
    return null;
  }

  if (isLoading) {
    return (
      <Flex
        className={cnj(
          classes.searchPostScope,
          classes.previewContainerSkeleton,
          isComment && classes.commentContainer,
          skeletonClassName
        )}
      >
        {cssVars}
        <Skeleton
          className={cnj(
            classes.skeleton__img,
            !isComment && classes.skeleton__img_full
          )}
        />
        <Flex
          className={cnj(
            classes.textWrapper,
            isComment && classes.commentTextWrapper
          )}
        >
          <Skeleton
            className={cnj(
              classes.skeleton__1,
              isComment && classes.skeleton__1_c
            )}
          />
          <Skeleton
            className={cnj(
              classes.skeleton__2,
              isComment && classes.skeleton__2_c
            )}
          />
        </Flex>
      </Flex>
    );
  }
  if (error || !data) {
    return fallback || null;
  }

  return (
    <Flex className={cnj(classes.root, classes.searchPostScope, className)}>
      {cssVars}
      <BaseButton target="_blank" href={url}>
        <Flex
          className={cnj(
            classes.previewContainer,
            isComment && classes.commentContainer
          )}
        >
          {data.image ? (
            <Image
              className={cnj(classes.img)}
              src={data.image}
              alt={data.title}
            />
          ) : (
            <Flex className={classes.noMedialRoot}>
              <IconButton
                colorSchema="semi-transparent"
                noHover
                size="mlg"
                variant="circle"
                name="eye-slash"
              />
              <Typography
                textAlign="center"
                mt={12}
                color="smoke_coal"
                font="700"
                size={16}
              >
                {t('no_media_available')}
              </Typography>
            </Flex>
          )}

          <Flex
            className={cnj(
              classes.textWrapper,
              isComment && classes.commentTextWrapper
            )}
          >
            {data.siteName && (
              <Typography
                className={classes.siteName}
                color="darkSmoke_lead"
                size={12}
                font="700"
                height={14}
              >
                {data.siteName}
              </Typography>
            )}
            {data.title && (
              <Typography color="thirdText" font="700" size={20} height={24}>
                {data.title}
              </Typography>
            )}
            {!isComment && data.description && (
              <Typography className={classes.description} size={15}>
                {data.description}
              </Typography>
            )}
          </Flex>
        </Flex>
      </BaseButton>
      {isCloseable && (
        <IconButton
          size="md15"
          colorSchema="graySecondary"
          variant="rectangle"
          type="fas"
          className={classes.closeIcon}
          name="times"
          onClick={toggle}
        />
      )}
    </Flex>
  );
};

export default React.memo(LinkPreview, isPropsChanged(['url']));
