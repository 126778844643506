import React from 'react';
import { APP_ENTITIES, translateReplacer, useTranslation } from '@lobox/utils';
import ParseTextStringCP, {
  DefaultTagTextComponent,
  DefaultTagLinkComponent,
  DefaultTagTooltipComponent,
} from '@shared/components/molecules/TranslateReplacer';

interface PostHeaderTextProps {
  objectId?: string;
  postOwnerTitle: string;
  locationTitle?: string;
  tags?: Array<any>;
  isSharedEntity?: boolean;
  sharedEntityTitle?: string;
  onPersonsClicked?: () => void;
  onLocationClicked?: () => void;
  height?: number;
}
const PostHeaderText = ({
  objectId,
  postOwnerTitle,
  locationTitle,
  tags,
  isSharedEntity,
  sharedEntityTitle,
  onPersonsClicked,
  onLocationClicked,
  height,
}: PostHeaderTextProps): JSX.Element => {
  const { t } = useTranslation();

  // TODO display share page and highlight

  const getTagObjectProps = (tag: any, keys: any) => {
    const { userKey, pageKey } = keys;
    return tag.userType === APP_ENTITIES.page
      ? tag.pageInfo?.[pageKey]
      : userKey === 'fullName'
        ? tag?.profileInfo?.fullName ||
          `${tag.profileInfo?.name} ${tag.profileInfo?.surname}`
        : tag.profileInfo?.[userKey];
  };
  const taggedOnePerson = !!tags && tags.length === 1;
  const taggedTwoPerson = !!tags && tags.length === 2;
  const taggedMultiPerson = !!tags && tags.length > 1;
  const hasLocation = !!locationTitle;

  if (isSharedEntity) {
    return (
      <ParseTextStringCP
        height={height}
        textString={translateReplacer(t('name_shared_entity'), [
          postOwnerTitle,
          sharedEntityTitle,
        ])}
        tagComponentMap={{
          0: (text) => (
            <DefaultTagLinkComponent
              height={height}
              text={text}
              objectId={objectId}
            />
          ),
          1: (text) => <DefaultTagTextComponent height={height} text={text} />,
        }}
      />
    );
  }
  if (taggedOnePerson && !hasLocation) {
    return (
      <ParseTextStringCP
        height={height}
        textString={translateReplacer(t('name_is_with_name'), [
          postOwnerTitle,
          getTagObjectProps(tags[0], {
            userKey: 'fullName',
            pageKey: 'title',
          }),
        ])}
        tagComponentMap={{
          0: (text) => (
            <DefaultTagLinkComponent
              height={height}
              text={text}
              objectId={objectId}
            />
          ),
          1: (text) => (
            <DefaultTagLinkComponent
              onClick={onPersonsClicked}
              text={text}
              objectId={getTagObjectProps(tags[0], {
                userKey: 'userId',
                pageKey: 'id',
              })}
            />
          ),
        }}
      />
    );
  }
  if (taggedTwoPerson && !hasLocation) {
    return (
      <ParseTextStringCP
        height={height}
        textString={translateReplacer(t('name_is_with_name_and_name'), [
          postOwnerTitle,
          getTagObjectProps(tags[0], {
            userKey: 'fullName',
            pageKey: 'title',
          }),
          getTagObjectProps(tags[1], {
            userKey: 'fullName',
            pageKey: 'title',
          }),
        ])}
        tagComponentMap={{
          0: (text) => (
            <DefaultTagLinkComponent text={text} objectId={objectId} />
          ),
          1: (text) => (
            <DefaultTagLinkComponent
              onClick={onPersonsClicked}
              text={text}
              objectId={getTagObjectProps(tags[0], {
                userKey: 'userId',
                pageKey: 'id',
              })}
            />
          ),
          2: (text) => (
            <DefaultTagLinkComponent
              onClick={onPersonsClicked}
              text={text}
              objectId={getTagObjectProps(tags[1], {
                userKey: 'userId',
                pageKey: 'id',
              })}
            />
          ),
        }}
      />
    );
  }

  if (taggedMultiPerson && !hasLocation) {
    return (
      <ParseTextStringCP
        height={height}
        textString={translateReplacer(t('name_is_with_name_and_others'), [
          postOwnerTitle,
          getTagObjectProps(tags[0], {
            userKey: 'fullName',
            pageKey: 'title',
          }),
          `${tags.length - 1} ${tags.length > 2 ? t('others') : t('other')}`,
        ])}
        tagComponentMap={{
          0: (text) => (
            <DefaultTagLinkComponent text={text} objectId={objectId} />
          ),
          1: (text) => (
            <DefaultTagLinkComponent
              onClick={onPersonsClicked}
              text={text}
              objectId={getTagObjectProps(tags[0], {
                userKey: 'userId',
                pageKey: 'id',
              })}
            />
          ),
          2: (text) => (
            <DefaultTagTooltipComponent
              onClick={onPersonsClicked}
              text={text}
              items={tags.slice(1)}
              getItemTitle={(item) =>
                getTagObjectProps(item, {
                  userKey: 'fullName',
                  pageKey: 'title',
                })
              }
              getItemId={(item) =>
                getTagObjectProps(item, {
                  userKey: 'userId',
                  pageKey: 'id',
                })
              }
            />
          ),
        }}
      />
    );
  }
  if ((taggedOnePerson || taggedMultiPerson) && hasLocation) {
    return (
      <ParseTextStringCP
        height={height}
        textString={translateReplacer(t('name_is_with_name_in_location'), [
          postOwnerTitle,
          taggedOnePerson
            ? getTagObjectProps(tags[0], {
                userKey: 'fullName',
                pageKey: 'title',
              })
            : `${tags.length} ${t('others')}`,
          locationTitle,
        ])}
        tagComponentMap={{
          0: (text) => (
            <DefaultTagLinkComponent text={text} objectId={objectId} />
          ),
          1: (text) =>
            taggedOnePerson ? (
              <DefaultTagLinkComponent
                text={text}
                objectId={getTagObjectProps(tags[0], {
                  userKey: 'userId',
                  pageKey: 'id',
                })}
              />
            ) : (
              <DefaultTagTooltipComponent
                onClick={onPersonsClicked}
                text={text}
                items={tags}
                getItemTitle={(item) =>
                  getTagObjectProps(item, {
                    userKey: 'fullName',
                    pageKey: 'title',
                  })
                }
                getItemId={(item) =>
                  getTagObjectProps(item, {
                    userKey: 'userId',
                    pageKey: 'id',
                  })
                }
              />
            ),
          2: (text) => (
            <DefaultTagTextComponent
              height={height}
              onClick={onLocationClicked}
              text={text}
            />
          ),
        }}
      />
    );
  }
  if (hasLocation) {
    return (
      <ParseTextStringCP
        height={height}
        textString={translateReplacer(t('name_is_at_location'), [
          postOwnerTitle,
          locationTitle,
        ])}
        tagComponentMap={{
          0: (text) => (
            <DefaultTagLinkComponent
              height={height}
              text={text}
              objectId={objectId}
            />
          ),
          1: (text) => (
            <DefaultTagTextComponent
              height={height}
              onClick={onLocationClicked}
              text={text}
            />
          ),
        }}
      />
    );
  }
  return (
    <DefaultTagLinkComponent
      height={height}
      text={postOwnerTitle}
      objectId={objectId}
    />
  );
};

export default PostHeaderText;
