import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import DateView from '@lobox/uikit/DateView';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import { PostReactions, useTranslation } from '@lobox/utils';
import useAddReaction from '@shared/hooks/api-hook/useAddReaction';
import useRemoveReaction from '@shared/hooks/api-hook/useRemoveReaction';
import ReactionMenu from '../../ReactionsMenu';
import classes from './PureComment.actions.module.scss';

interface PureCommentReactionProps {
  onReplyClick: () => void;
  createdDate: Date;
  comment: any;
  onAddReaction: (reaction: string, res: any) => void;
  onRemoveReaction: (res: any) => void;
}

const PureCommentActions: React.FC<PureCommentReactionProps> = ({
  onReplyClick,
  createdDate,
  comment,
  onAddReaction,
  onRemoveReaction,
}) => {
  const { t } = useTranslation();
  const { mutate: addReaction } = useAddReaction();
  const { mutate: removeReaction } = useRemoveReaction();

  const { myAction } = comment;

  const removeAct = (type: string) => {
    removeReaction(
      {
        commentId: comment?.id,
        type,
      },
      {
        onSuccess: (data: any) => onRemoveReaction(data),
      }
    );
  };

  const addAct = (type: string) => {
    addReaction(
      {
        commentId: comment?.id,
        type,
      },
      {
        onSuccess: (data: any) => onAddReaction(type, data),
      }
    );
  };

  const handleReactionClick = (key: string) => () => {
    if (key === myAction) removeAct(myAction);
    else addAct(key);
  };

  const handleReactClicked = () => {
    if (myAction) removeAct(myAction);
    else addAct(PostReactions.like);
  };

  const Action = (
    <Typography
      font="bold"
      size={15}
      height={21}
      className={cnj(
        classes.reactionButtonLabel,
        myAction === PostReactions.like
          ? classes.reactionButtonLabel_like
          : myAction === PostReactions.boost
            ? classes.reactionButtonLabel_boost
            : myAction === PostReactions.celebrate
              ? classes.reactionButtonLabel_celebrate
              : myAction === PostReactions.disLike
                ? classes.reactionButtonLabel_dislike
                : myAction === PostReactions.notRelevant
                  ? classes.reactionButtonLabel_irrelevant
                  : classes.reactionButtonLabel_primary
      )}
    >
      {myAction === PostReactions.like
        ? 'Like'
        : myAction === PostReactions.boost
          ? 'Boost'
          : myAction === PostReactions.celebrate
            ? 'Celebrate'
            : myAction === PostReactions.disLike
              ? 'Dislike'
              : myAction === PostReactions.notRelevant
                ? 'Not relevant'
                : 'Like'}
    </Typography>
  );

  return (
    <Flex flexDir="row" className={classes.commentAction}>
      {myAction ? (
        <BaseButton onClick={handleReactClicked} className={classes.actionBtn}>
          {Action}
        </BaseButton>
      ) : (
        <ReactionMenu
          button={
            <Flex>
              <BaseButton className={classes.actionBtn}>{Action}</BaseButton>
            </Flex>
          }
          onLikeClick={handleReactionClick(PostReactions.like)}
          onBoostClick={handleReactionClick(PostReactions.boost)}
          onCelebrateClick={handleReactionClick(PostReactions.celebrate)}
          onDisLikeClick={handleReactionClick(PostReactions.disLike)}
          onNotRelevantClick={handleReactionClick(PostReactions.notRelevant)}
        />
      )}

      <BaseButton onClick={onReplyClick} className={classes.actionBtn}>
        <Typography font="bold" color="primaryText" height={21} size={15}>
          {t('reply')}
        </Typography>
      </BaseButton>
      {createdDate ? (
        <DateView
          value={createdDate}
          shortFormat
          color="primaryText"
          height={17}
          size={12}
        />
      ) : null}
    </Flex>
  );
};

export default PureCommentActions;
