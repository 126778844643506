import React from 'react';
import { ProfileContext } from '@shared/contexts/profile.provider';

type ModalsType = {
  reFetchAboutSectionQuery: Function;
  reFetchBusinessPageQuery: Function;
  reFetchAuthUserQuery: Function;
  getPageCreationFormValue: (key: string) => any;
  setPageCreationFormData: Function;
};

const useProfile = (): ModalsType => React.useContext(ProfileContext) as any;

export default useProfile;
