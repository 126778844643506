import React, { useCallback, useEffect, useRef } from 'react';
import Flex from '@lobox/uikit/Flex';
import Input from '../Comments.input';

interface Props {
  inputAlignment?: 'start' | 'center';
  className?: string;
  selectedComment?: any;
  onEditSuccess?: Function;
  avatarSize?: 'sm' | 'lg' | 'xs';
  inputWrapperClassName?: string;
}

const EditComment = ({
  inputAlignment,
  className,
  selectedComment,
  onEditSuccess,
  avatarSize = 'sm',
  inputWrapperClassName,
  ...rest
}: Props): JSX.Element => {
  const ref = useRef<any>();
  const setRef = useCallback((refs) => {
    ref.current = refs;
  }, []);
  useEffect(() => {
    setTimeout(() => {
      const quill = ref.current?.quill;
      var length = quill.getLength();
      quill.setSelection(length);
    }, 200);
  }, []);

  return (
    <Flex className={className}>
      <Input
        isEdit
        alignment={inputAlignment}
        avatarSize={avatarSize}
        selectedComment={selectedComment}
        onEditSuccess={onEditSuccess}
        classNames={{ wrapperClassName: inputWrapperClassName }}
        setRichTextRef={setRef}
        {...rest}
      />
    </Flex>
  );
};

export default EditComment;
