import React, { useRef } from 'react';
import Flex from '@lobox/uikit/Flex';
import Tooltip from '@lobox/uikit/Tooltip';
import useMedia from '@lobox/uikit/utils/useMedia';
import { useTranslation } from '@lobox/utils';
import Like from '@shared/components/molecules/Reactions/Like';
import Boost from '@shared/components/molecules/Reactions/Boost';
import Celebrate from '@shared/components/molecules/Reactions/Celebrate';
import NotRelevant from '@shared/components/molecules/Reactions/NotRelevant';
import DisLike from '@shared/components/molecules/Reactions/DisLike';
import classes from './ReactionsMenu.component.module.scss';
import PopperMenu from '@lobox/uikit/PopperMenu';

interface ReactionsMenuProps {
  button: any;
  onLikeClick: any;
  onBoostClick: any;
  onCelebrateClick: any;
  onDisLikeClick: any;
  onNotRelevantClick: any;
}

const ReactionsMenu: React.FC<ReactionsMenuProps> = ({
  button,
  onLikeClick,
  onBoostClick,
  onCelebrateClick,
  onDisLikeClick,
  onNotRelevantClick,
}) => {
  const { t } = useTranslation();
  const popperRef = useRef(null);
  const { isMoreThanTablet } = useMedia();

  const handleClick = (callback) => () => {
    callback?.();
  };

  return (
    <PopperMenu
      showWithHover={isMoreThanTablet}
      noDrawer={true}
      buttonComponent={button}
      closeOnScroll
      placement="top-start"
      closeDelay={0}
      menuClassName={classes.menuClassName}
    >
      <Flex ref={popperRef} flexDir="row">
        <Tooltip
          placement="top"
          trigger={
            <Like
              hasHover
              className={classes.reactionIcon}
              onClick={handleClick(onLikeClick)}
            />
          }
        >
          {t('like')}
        </Tooltip>
        <Tooltip
          placement="top"
          content=""
          trigger={
            <Boost
              className={classes.reactionIcon}
              onClick={handleClick(onBoostClick)}
            />
          }
        >
          {t('boost')}
        </Tooltip>
        <Tooltip
          placement="top"
          trigger={
            <Celebrate
              className={classes.reactionIcon}
              onClick={handleClick(onCelebrateClick)}
            />
          }
        >
          {t('celebrate')}
        </Tooltip>
        <Tooltip
          placement="top"
          trigger={
            <DisLike
              className={classes.reactionIcon}
              onClick={handleClick(onDisLikeClick)}
            />
          }
        >
          {t('dislike')}
        </Tooltip>
        <Tooltip
          placement="top"
          trigger={<NotRelevant onClick={handleClick(onNotRelevantClick)} />}
        >
          {t('not_relevant')}
        </Tooltip>
      </Flex>
    </PopperMenu>
  );
};

export default ReactionsMenu;
