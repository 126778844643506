import React, { useState } from 'react';
import Spinner from '@lobox/uikit/Spinner';
import Tooltip from '@lobox/uikit/Tooltip';
import Typography from '@lobox/uikit/Typography';
import useGetPostCommentReactionsInfo from '@shared/hooks/api-hook/useGetPostCommentReactionsInfo';
import { useTranslation } from '@lobox/utils';
import type { FC, PropsWithChildren } from 'react';
import classes from './ReactionInfo.component.module.scss';

interface ReactionInfoProps {
  postId?: string;
  commentId?: string;
  type?: string;
  title?: string;
}

const ReactionInfo: FC<PropsWithChildren<ReactionInfoProps>> = (props) => {
  const { children, commentId, postId, type, title } = props;

  const { t } = useTranslation();
  const [info, setInfo] = useState<any>();

  const { mutate } = useGetPostCommentReactionsInfo({
    commentId,
    postId,
    type,
  });

  const totalCount = info?.totalElements || 0;
  const overflowCount = totalCount - info?.content?.length;

  const fetchData = () => {
    if (info) {
      return;
    }

    mutate(
      {},
      {
        onSuccess: (data) => {
          setInfo(data);
        },
      }
    );
  };

  return (
    <Tooltip
      placement="top"
      trigger={<div onMouseEnter={fetchData}>{children}</div>}
    >
      {!info ? (
        <Spinner />
      ) : (
        <>
          <Typography font="700" size={14} color="disabledGrayDark" mb={2}>
            {title}
          </Typography>

          {info?.content?.map((item: any) => (
            <Typography
              isTruncated
              font="400"
              size={14}
              color="tooltipText"
              key={item?.id}
              className={classes.truncate}
            >
              {item?.actorTitle}
            </Typography>
          ))}

          {overflowCount > 5 && (
            <Typography
              font="400"
              size={14}
              color="disabledGrayDark"
              mt={2}
            >{`${t('and')} ${overflowCount} ${t('others')}...`}</Typography>
          )}
        </>
      )}
    </Tooltip>
  );
};

export default ReactionInfo;
