import React, { Fragment } from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex/index';
import Tooltip from '@lobox/uikit/Tooltip';
import Typography from '@lobox/uikit/Typography';
import ObjectLink from '@shared/components/molecules/ObjectLink';
import type { ReactNode } from 'react';
import classes from './ParseTextString.module.scss';

type TagComponentMap = {
  [key: number]: React.ComponentType<{ children: ReactNode }>;
};

type Props = {
  height?: number;
  textString: string;
  tagComponentMap?: TagComponentMap;
};

export const DefaultTagTextComponent = ({
  onClick,
  text,
  height = 21,
}: {
  onClick?: () => void;
  text: string;
  height?: number;
}) => {
  const CP = onClick ? BaseButton : Fragment;

  return (
    <CP {...(onClick ? { onClick } : {})}>
      <Typography color="thirdText" font="700" size={15} height={height}>
        {text}
      </Typography>
    </CP>
  );
};

export const DefaultTagLinkComponent = ({
  text,
  objectId,
  onClick,
  height,
}: {
  objectId: string;
  text: string;
  onClick?: () => void;
  height?: number;
}) => {
  const CP = objectId && !onClick ? ObjectLink : Fragment;

  return (
    <CP {...(objectId && !onClick ? { objectId, enableHover: true } : {})}>
      <DefaultTagTextComponent onClick={onClick} text={text} height={height} />
    </CP>
  );
};

interface DefaultTagTooltipComponentProps {
  text: string;
  items: Array<any>;
  getItemTitle: (any) => string;
  getItemId: (any) => string;
  onClick?: () => void;
}

export const DefaultTagTooltipComponent = ({
  text,
  items,
  getItemTitle,
  getItemId,
  onClick,
}: DefaultTagTooltipComponentProps) => {
  const CP = onClick ? Fragment : ObjectLink;

  return (
    <Tooltip
      keepMounted
      trigger={
        <BaseButton onClick={onClick}>
          <Typography
            color="thirdText"
            font="bold"
            size={15}
            height={18}
            className={classes.othersWrapper}
          >
            {text}
          </Typography>
        </BaseButton>
      }
    >
      <Flex className={classes.tagsWrapper}>
        {items.map((item: any) => (
          <CP
            {...(onClick ? {} : { objectId: getItemId(item) })}
            key={getItemId(item)}
          >
            <Typography
              color="tooltipText"
              size={14}
              isWordWrap
              isTruncated
              lineNumber={1}
              className={cnj(classes.typography, classes.wordBreak)}
            >
              {getItemTitle(item)}
            </Typography>
          </CP>
        ))}
      </Flex>
    </Tooltip>
  );
};
const ParseTextString = ({
  height = 18,
  textString,
  tagComponentMap,
}: Props) => {
  const startTag = '<b>';
  const endTag = '</b>';

  let output: ReactNode[] = [];
  let currentIndex = 0;
  let tagCount = 0;

  while (true) {
    const startIndex = textString.indexOf(startTag, currentIndex);
    if (startIndex === -1) {
      output.push(
        <span key={currentIndex}>{textString.substring(currentIndex)}</span>
      );
      break;
    }

    const endIndex = textString.indexOf(endTag, currentIndex);
    if (endIndex === -1) {
      output.push(
        <span key={currentIndex}>{textString.substring(currentIndex)}</span>
      );
      break;
    }

    output.push(
      <span key={`${currentIndex}_${startIndex}`}>
        {textString.substring(currentIndex, startIndex)}
      </span>
    );

    const innerText = textString.substring(
      startIndex + startTag.length,
      endIndex
    );

    const tagComponent = tagComponentMap
      ? tagComponentMap[tagCount]
      : (text) => <DefaultTagTextComponent height={20} text={text} />;
    output.push(tagComponent(innerText));

    currentIndex = endIndex + endTag.length;
    tagCount += 1;
  }
  return (
    <Typography
      className={classes.headTexts}
      color="leadText"
      size={15}
      font="400"
      height={height}
    >
      {output}
    </Typography>
  );
};

export default ParseTextString;
