import classes from './FeedCard.Single.private.module.scss';

import React from 'react';
import NotFound from '@lobox/uikit/NotFound';
import {
  redirectUrl,
  routeNames,
  useAuthState,
  useTranslation,
} from '@lobox/utils';
import urls from '@shared/constants/urls';
import { useHistory } from '@lobox/utils';
import Private from '@shared/svg/Private';

interface FeedCardPrivateOwnerProps {
  ownerTitle: string;
  ownerUserName: string;
}

const FeedCardPrivateOwner = ({
  ownerTitle,
  ownerUserName,
}: FeedCardPrivateOwnerProps): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const isLoggedIn = useAuthState('isLoggedIn');

  const handleClick = () => {
    history.push(`/${ownerUserName}`);
  };
  const onBack = () => {
    if (isLoggedIn) {
      history.push(routeNames.home);
    } else {
      redirectUrl(`${urls.login}?redirect=${routeNames.home}`);
    }
  };

  return (
    <NotFound
      title={t('feedcard_single_private')}
      message={t('feedcard_single_private_msg')}
      image={<Private />}
      secondaryActionProps={{
        label: t('back'),
        onClick: onBack,
      }}
      primaryActionProps={{
        label: `${ownerTitle}'s profile`,
        onClick: handleClick,
        className: classes.primaryButton,
        labelProps: {
          isTruncated: true,
        },
      }}
    />
  );
};

export default FeedCardPrivateOwner;
