import React from 'react';
import ScheduleEvensCard from '@shared/components/molecules/ScheduleEvensCard';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import Button from '@lobox/uikit/Button';
import { useTranslation } from '@lobox/utils';
import { useScheduleDispatch } from '@/shared/contexts/Schedule/schedule.provider';
import type { ISchedulesEvent } from '@lobox/utils';
import type { FC } from 'react';

interface SharedBirthdayProps {
  event: ISchedulesEvent;
  onAfterClick?: VoidFunction;
  hideActionButtons?: boolean;
}

const SharedBirthday: FC<SharedBirthdayProps> = (props) => {
  const { event, onAfterClick, hideActionButtons } = props;

  const { t } = useTranslation();

  const scheduleDispatch = useScheduleDispatch();

  const openDetailModal = () => {
    scheduleDispatch({
      type: 'SET_SCHEDULE_CREATION_MODAL_DATA',
      payload: {
        visibleForm: false,
        schedulesEventType: event.schedulesEventType,
        eventId: event.id,
      },
    });

    // The above function dispatch 👆🏻 requires to be in context
    // of ScheduleProvider which is not accessible inside
    // share modal so we need to pass it and use it like this 👇🏻
    event?.openDetailModal?.();

    onAfterClick?.();
  };

  return (
    <>
      <BaseButton onClick={openDetailModal}>
        <ScheduleEvensCard disableOnClick noActionButtons event={event} />
      </BaseButton>
    </>
  );
};

export default SharedBirthday;
