import React, { useState } from 'react';
import PureComment from '../PureComment';
import useMedia from '@shared/uikit/utils/useMedia';
import Input from '../Comments.input';
import Flex from '@shared/uikit/Flex';

const ReplyCommentItem = ({
  data,
  refetchCommentList,
  refetchReplyList,
  onReplyClick,
  setFeedCommentCounter,
  replyProps,
}: any) => {
  const [isReplyClicked, setIsReplyClicked] = useState(false);
  const { isTabletAndLess } = useMedia();
  const { classes, setRichTextRef, firstReplyId, handleSuccessReply } =
    replyProps;

  const handleReply = (...args) => {
    setIsReplyClicked(true);
    onReplyClick?.(...args);
  };
  const handleReplySuccess = (...args) => {
    setIsReplyClicked(false);
    handleSuccessReply?.(...args);
  };

  return (
    <>
      <PureComment
        data={data}
        isReply
        refetchCommentList={refetchCommentList}
        refetchReplyList={refetchReplyList}
        onReplyClick={handleReply}
        setFeedCommentCounter={setFeedCommentCounter}
      />

      {isReplyClicked && !isTabletAndLess && (
        <Flex className={classes.replyInput} key={data.id}>
          <Input
            isReply
            avatarSize="xs"
            setRichTextRef={setRichTextRef}
            selectedComment={data}
            firstReplyId={firstReplyId}
            onReplySuccess={handleReplySuccess}
            setFeedCommentCounter={setFeedCommentCounter}
          />
        </Flex>
      )}
    </>
  );
};

export default ReplyCommentItem;
