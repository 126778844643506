import React from 'react';
import ErrorBoundary from '@lobox/uikit/ErrorBoundary/ErrorBoundary';
import Layout from './layout';
import { FeedElementProvider } from './Context/feedElement.provider';
import type { VariantType } from './Context/feedElement.provider';
import type { IFeedElement } from '@lobox/utils';

interface PostProps {
  feedElement: IFeedElement;
  variant?: VariantType;
  visibleNetworkInfoOnHeader?: boolean;
  isHiddenList?: boolean;
  showInitialComments?: boolean;
  onAddPost?: (feedElement: IFeedElement) => void;
  onReplacePost?: (feedElement: IFeedElement) => void;
  onDeletePost?: (feedElement: IFeedElement) => void;
  onUnsavePost?: (feedElement: IFeedElement) => void;
  onUnHidePost?: (feedElement: IFeedElement) => void;
  className?: string;
}

const FeedElement = ({
  feedElement,
  isHiddenList,
  variant = 'simple',
  visibleNetworkInfoOnHeader,
  onAddPost,
  onReplacePost,
  onDeletePost,
  onUnsavePost,
  onUnHidePost,
  showInitialComments,
  className,
}: PostProps): JSX.Element => (
  <ErrorBoundary renderFallback={() => <span />}>
    <FeedElementProvider
      initialValue={{
        feedElement,
        isHiddenList,
        variant,
        onAddPost,
        onReplacePost,
        onDeletePost,
        onUnsavePost,
        onUnHidePost,
        visibleNetworkInfoOnHeader,
      }}
    >
      <Layout className={className} showInitialComments={showInitialComments} />
    </FeedElementProvider>
  </ErrorBoundary>
);

export default FeedElement;
