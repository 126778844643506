import React from 'react';
import Button from '@lobox/uikit/Button';
import Flex from '@lobox/uikit/Flex';
import useMedia from '@lobox/uikit/utils/useMedia';
import {
  PostReactions,
  preventClickHandler,
  useTranslation,
} from '@lobox/utils';
import Like from '@shared/components/molecules/Reactions/Like';
import Boost from '@shared/components/molecules/Reactions/Boost';
import Celebrate from '@shared/components/molecules/Reactions/Celebrate';
import DisLike from '@shared/components/molecules/Reactions/DisLike';
import NotRelevant from '@shared/components/molecules/Reactions/NotRelevant';
import useAddReaction from '@shared/hooks/api-hook/useAddReaction';
import useRemoveReaction from '@shared/hooks/api-hook/useRemoveReaction';
import LikeDark from '@shared/svg/LikeDark';
import ReactionMenu from '../../Components/ReactionsMenu';
import useFeedElement from '../../Context/useFeedElement';
import classes from './Action.reactions.module.scss';
import cnj from '@lobox/uikit/utils/cnj';

const FeedActionsReactions: React.FC = () => {
  const { t } = useTranslation();
  const { mutate: addReaction } = useAddReaction();
  const { mutate: removeReaction } = useRemoveReaction();
  const { isTabletAndLess } = useMedia();
  const { myReaction, post, addReactionLocally, removeReactionLocally } =
    useFeedElement();

  const getReactionIcon = (key: string) =>
    key === PostReactions.like
      ? {
          icon: <Like size="small" hideContainer />,
          text: t('like'),
        }
      : key === PostReactions.boost
        ? { icon: <Boost size="small" hideContainer />, text: t('boost') }
        : key === PostReactions.celebrate
          ? {
              icon: <Celebrate size="small" hideContainer />,
              text: t('celebrate'),
            }
          : key === PostReactions.disLike
            ? {
                icon: <DisLike size="small" hideContainer />,
                text: t('dislike'),
              }
            : key === PostReactions.notRelevant
              ? {
                  icon: <NotRelevant size="small" hideContainer />,
                  text: t('not_relevant'),
                }
              : { icon: <LikeDark width={22} height={22} />, text: t('like') };

  const removeAct = (type: string) => {
    removeReaction(
      {
        postId: post?.id,
        type,
      },
      {
        onSuccess: (data: any) => removeReactionLocally(data),
      }
    );
  };

  const addAct = (type: string) => {
    addReaction(
      {
        postId: post?.id,
        type,
      },
      {
        onSuccess: (data: any) => {
          addReactionLocally(type as any, data);
        },
      }
    );
  };

  const handleReactionClick = (key: string) => () => {
    if (key === myReaction?.type) removeAct(myReaction.type);
    else addAct(key);
  };

  const myReact = getReactionIcon(myReaction?.type as string);

  const handleReactionButtonCLicked = (e) => {
    preventClickHandler(e);
    if (myReaction?.type) removeAct(myReaction?.type);
    else addAct(PostReactions.like);
  };

  const reactionClassName =
    myReaction?.type === PostReactions.like
      ? classes.reactionButtonLabel_like
      : myReaction?.type === PostReactions.boost
        ? classes.reactionButtonLabel_boost
        : myReaction?.type === PostReactions.celebrate
          ? classes.reactionButtonLabel_celebrate
          : myReaction?.type === PostReactions.disLike
            ? classes.reactionButtonLabel_dislike
            : myReaction?.type === PostReactions.notRelevant
              ? classes.reactionButtonLabel_irrelevant
              : classes.reactionButtonLabel_primary;

  return myReaction?.type ? (
    <Button
      schema="ghost"
      leftSvg={myReact.icon}
      label={myReact.text}
      className={cnj(classes.reactionButton, reactionClassName)}
      labelClassName={classes.reactionButtonLabel}
      onClick={handleReactionButtonCLicked}
      labelFont="500"
    />
  ) : (
    <>
      <ReactionMenu
        button={
          <Flex className={cnj(classes.reactionButton, reactionClassName)}>
            <Button
              schema="ghost"
              labelFont="500"
              leftSvg={myReact.icon}
              label={myReact.text}
              labelClassName={classes.reactionButtonLabel}
              onClick={
                isTabletAndLess ? undefined : handleReactionButtonCLicked
              }
            />
          </Flex>
        }
        onLikeClick={handleReactionClick(PostReactions.like)}
        onBoostClick={handleReactionClick(PostReactions.boost)}
        onCelebrateClick={handleReactionClick(PostReactions.celebrate)}
        onDisLikeClick={handleReactionClick(PostReactions.disLike)}
        onNotRelevantClick={handleReactionClick(PostReactions.notRelevant)}
      />
    </>
  );
};

export default FeedActionsReactions;
