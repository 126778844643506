import React from 'react';
import NotFound from '@lobox/uikit/NotFound';
import useTheme from '@lobox/uikit/utils/useTheme';
import { routeNames, useAuthState, useTranslation } from '@lobox/utils';
import urls from '@shared/constants/urls';
import { useHistory } from '@lobox/utils';
import NoContent from '@shared/svg/NoContent';

interface FeedCardErrorProps {
  onBack?: () => void;
}

const FeedCardError = ({ onBack }: FeedCardErrorProps): JSX.Element => {
  const { isDark } = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const isLoggedIn = useAuthState('isLoggedIn');

  const handleClickHome = () => {
    if (isLoggedIn) {
      history.push(routeNames.home);
    } else {
      window.location.href = `${urls.login}?redirect=${routeNames.home}`;
    }
  };

  return (
    <NotFound
      title={t('no_content_exist')}
      message={t('no_content_exist_msg')}
      image={<NoContent isDark={isDark} />}
      secondaryActionProps={
        onBack
          ? {
              label: t('back'),
              onClick: onBack,
            }
          : undefined
      }
      primaryActionProps={{
        label: t('go_to_home_p'),
        onClick: handleClickHome,
      }}
    />
  );
};

export default FeedCardError;
