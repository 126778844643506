import { getCommentReplies, useInfiniteQuery, QueryKeys } from '@lobox/utils';
import type { UseInfiniteQueryType, Comment as IComment } from '@lobox/utils';

type GetCommentRepliesProps = {
  commentId: string;
  enabled?: boolean;
  size?: number;
};
const useGetPostComments = ({
  commentId,
  enabled = true,
  size = 1,
}: GetCommentRepliesProps): UseInfiniteQueryType<IComment> =>
  useInfiniteQuery(
    [QueryKeys.commentReplies, commentId],
    {
      func: getCommentReplies,
      size,
      extraProps: {
        commentId,
      },
    },
    {
      enabled,
    }
  );

export default useGetPostComments;
