import React from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Media from '@lobox/uikit/Media';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import { applyResolutionToImageSrc, useTranslation } from '@lobox/utils';
import { usePlaceholder } from '@shared/hooks';
import JobCover from '@app/jobs/partials/components/JobCover';
import type { IJob } from '@lobox/utils';
import classes from './Entity.job.module.scss';
import useCssVariables from '@shared/hooks/useCssVariables';

interface SharedJobProps {
  className?: string;
  job: IJob;
  variant?: 'feed' | 'message';
  footerProps?: Partial<{
    classes: string;
    [x: string]: any;
  }>;
  hideActionButtons?: boolean;
}

const SharedJob = ({
  job,
  className,
  variant = 'feed',
  footerProps,
  hideActionButtons,
}: SharedJobProps): JSX.Element => {
  const { title, page, location, applicantsCount, viewCount } = job || {};
  const headerImg = usePlaceholder();

  let cover: string;
  let coverOpacity: number;
  if (page?.croppedHeaderImageUrl) {
    cover = applyResolutionToImageSrc(page?.croppedHeaderImageUrl, 'tiny');
    coverOpacity = 0.1;
  } else {
    cover = headerImg;
    coverOpacity = 0.2;
  }

  const isMessageVariant = variant === 'message';
  const { t } = useTranslation();
  const { isMoreThanTablet } = useMedia();

  const cssVars = useCssVariables({
    scope: classes.layoutWrapper,
    variables: {
      bgUrl: `url(${cover}) no-repeat center / cover`,
      opacity: coverOpacity,
    },
  });

  return (
    <Flex
      className={cnj(
        classes.jobEntityRoot,
        isMessageVariant && classes.jobEntityRootMessageVariant,
        className
      )}
    >
      <Flex className={classes.layoutWrapper}>
        {cssVars}
        <Flex
          className={cnj(
            classes.layout,
            isMessageVariant && classes.layoutMessageVariant
          )}
        >
          <Flex
            className={cnj(
              classes.rightSection,
              isMessageVariant && classes.rightSectionMessageVariant
            )}
          >
            <Typography
              size={isMoreThanTablet ? 24 : 20}
              height={24}
              color="white"
              mb={isMoreThanTablet ? 2 : 'auto'}
              isWordWrap
              lineNumber={3}
            >
              <Typography
                font="700"
                size={isMoreThanTablet ? 24 : 20}
                color="white"
                className={classes.pageTitle}
                isTruncated
              >
                {page?.title} &nbsp;
              </Typography>
              {t('looking_new_member')}
            </Typography>
            <Typography
              size={14}
              color="white_80"
              height={18}
              mb="auto"
              isTruncated
            >
              {page?.usernameAtSign}
            </Typography>
            {!hideActionButtons && (
              <Media greaterThan="tablet">
                <Button
                  label={t('apply')}
                  labelFont="700"
                  labelColor="graphene"
                  leftIcon="target-mouse"
                  leftType="far"
                  leftColor="graphene"
                  leftSize={15}
                  className={cnj(
                    classes.button,
                    isMessageVariant && classes.buttonMessageVariant
                  )}
                />
              </Media>
            )}
          </Flex>
        </Flex>
        {!hideActionButtons && (
          <Media lessThan="midDesktop">
            <Flex className={classes.bottomWrapper}>
              <Button
                label={t('apply')}
                labelFont="700"
                labelColor="graphene"
                fullWidth
                className={cnj(
                  classes.button,
                  isMessageVariant && classes.buttonMessageVariant
                )}
              />
            </Flex>
          </Media>
        )}
      </Flex>
      <Flex
        {...footerProps}
        className={cnj(
          classes.footer,
          isMessageVariant && classes.footer_msg,
          footerProps?.classes
        )}
      >
        <Flex className={classes.innerFooterWrapper}>
          <JobCover
            data={{
              firstText: title?.label || t('no_title_entered'),
              secondText: page?.title,
              thirdText: location?.label || t('no_location_ent'),
              image: page?.imageUrl || page?.croppedImageUrl,
              jobId: job?.id,
            }}
            jobInfo={[
              { title: t('applicants'), value: applicantsCount || 0 },
              { title: t('views_cap'), value: viewCount || 0 },
            ]}
            isMessageVariant={isMessageVariant}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SharedJob;
