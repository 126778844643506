import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import Avatar from '@lobox/uikit/Avatar';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import {
  detectNewHashtags,
  getRichTextHashtags,
  sanitizeEmojies,
  getRichTextMentions,
} from '@lobox/uikit/RichText/richTextApi';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import RichText from '@lobox/uikit/RichText';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import useTheme from '@lobox/uikit/utils/useTheme';
import {
  addComment,
  APP_ENTITIES,
  editComment,
  QueryKeys,
  removeHtmlTagsInstring,
  removeRichTextBreakLines,
  useDebounceState,
  useEscPress,
  useReactMutation,
  useTranslation,
  useUpdateInfinityData,
} from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import ObjectLink from '@shared/components/molecules/ObjectLink';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import debounce from 'lodash/debounce';
import LinkPreview from '@shared/components/Organism/SearchPosts/SearchPosts.ogLink';
import { getExternalUrls } from '@shared/utils/getUrlsFromText';
import type { CreateCommentProps } from '@lobox/utils';
import useFeedElement from '../../Context/useFeedElement';
import { useFeedDispatch } from '../../Context/feedElement.provider';
import classes from './Comments.input.module.scss';
import { motion } from 'framer-motion';
import EmojiPickerButton from '@shared/uikit/EmojiPickerButton';
import { useFocusWithin } from '@shared/hooks/useFocusWithin';
import { flushSync } from 'react-dom';
import { focusAndScroll } from '@shared/utils-pkg/utils/focusAndScroll';
import { highlightComment } from '@shared/utils-pkg/utils/highlightComment';
import Spinner from '@shared/uikit/Spinner';

interface Focus {
  focus: Function;
  quill?: any;
}

interface CommentsInputProps {
  avatarSize?: 'sm' | 'smd' | 'lg' | 'xs';
  selectedComment?: any;
  isEdit?: boolean;
  onEditSuccess?: any;
  onReplySuccess?: any;
  isReply?: boolean;
  firstReplyId?: string;
  containerRef?: React.RefObject<HTMLElement>;
  setRichTextRef?: (ref: Focus) => void;
  setFeedCommentCounter?: (count: number) => void;
  classNames?: {
    wrapperClassName?: string;
  };
}

const CommentsInput = (
  {
    avatarSize = 'smd',
    selectedComment,
    isEdit,
    onEditSuccess,
    onReplySuccess,
    isReply,
    firstReplyId,
    containerRef,
    setRichTextRef,
    setFeedCommentCounter,
    classNames,
  }: CommentsInputProps,
  ref: any
) => {
  const { isDark } = useTheme();
  const commentCountRef = useRef(0);
  const textRef = useRef('');
  const { t } = useTranslation();
  const { value: urlInComment, setValue: setUrlInComment } = useDebounceState(
    '',
    250
  );
  const feedDispatch = useFeedDispatch();
  const [isSendDisabled, setIsSendDisabled] = useState(!textRef.current);
  const [isLoading, setIsLoading] = useState(false);
  const inputWrapperRef = useRef<HTMLElement>(null);

  const [richTextDefaultKey, setRichTextDefaultKey] = useState(0);
  const [preventSubmit, setPreventSubmit] = useState(false);
  const { mutate: _addComment, isLoading: addLoading } = useReactMutation({
    apiFunc: addComment,
  });
  const { mutate: _editComment, isLoading: editLoading } = useReactMutation({
    apiFunc: editComment,
  });
  const { isTabletAndLess } = useMedia();
  const { post, variant } = useFeedElement();
  const dispatch = useFeedDispatch();
  const quillRef = useRef<any>(null);

  const commentKey = [QueryKeys.postComments, post.id];

  const repliesKey = [
    QueryKeys.commentReplies,
    selectedComment?.replyToId || selectedComment?.id,
  ];

  const {
    get: getCommentLocally,
    add: addCommentLocally,
    replace: replaceCommentLocally,
  } = useUpdateInfinityData(commentKey);

  React.useEffect(() => {
    commentCountRef.current = Number(post?.commentCounter) || 0;
  }, [post?.commentCounter]);

  const { add: addReplyLocally, replace: replaceReplyLocally } =
    useUpdateInfinityData(repliesKey);

  const { getAppObjectPropValue, businessPage } = useGetAppObject();

  const avatarImage = getAppObjectPropValue({
    userKey: 'croppedImageUrl',
    pageKey: 'croppedImageUrl',
  });

  const authId = getAppObjectPropValue({
    userKey: 'id',
    pageKey: 'id',
  });

  function handleChange(text: string) {
    textRef.current = text;
    setUrlInComment(getExternalUrls(text)?.[0]);
    setIsSendDisabled(!removeHtmlTagsInstring(textRef.current)?.trim?.());
  }

  const scrollTop = () => {
    if (containerRef?.current && isTabletAndLess && !selectedComment) {
      containerRef.current.scroll({ top: 0, behavior: 'smooth' });
    }
  };

  const createComment = () => {
    flushSync(() => setIsLoading(true));
    setUrlInComment('');
    if (!addLoading && !editLoading && !preventSubmit) {
      setPreventSubmit(() => true);
      const _richTextInput = detectNewHashtags(textRef.current);
      const mentions = getRichTextMentions(_richTextInput);
      const hashtags = getRichTextHashtags(_richTextInput);
      const text = sanitizeEmojies(removeRichTextBreakLines(_richTextInput));
      if (isEdit) {
        const editReq = {
          body: text,
          postId: post.id,
          mentions,
          hashtags,
        };
        _editComment(
          { id: selectedComment.id, req: editReq },
          {
            onSuccess: (data) => {
              resetRichText();
              if (!selectedComment.replyToId) replaceCommentLocally(data);
              else replaceReplyLocally(data);

              onEditSuccess();
              setPreventSubmit(() => false);

              if (isTabletAndLess) {
                setTimeout(() => {
                  focusAndScroll(
                    document.querySelector(`[data-id="${selectedComment?.id}"]`)
                  );
                  highlightComment({
                    commentId: selectedComment?.id,
                    isReply: isReplyOrIsReplyTo || selectedComment?.isReply,
                  });
                }, 300);
              }
            },
            onError() {
              setPreventSubmit(() => false);
            },
            onSettled: () => {
              setIsLoading(false);
            },
          }
        );
      } else {
        const comment: CreateCommentProps = {
          body: text,
          postId: post.id,
          commentId: selectedComment?.replyToId || selectedComment?.id,
          mentions,
          hashtags,
          childCommentId: firstReplyId,
        };
        _addComment(
          { commentData: comment },
          {
            onSuccess: (data) => {
              scrollTop();
              if (!comment.commentId) {
                addCommentLocally({
                  ...data,
                  recentlyAdded: true,
                });
              } else {
                onReplySuccess?.(data);
                addReplyLocally({
                  ...data,
                  parentId: selectedComment.parentId || selectedComment.id,
                  recentlyAdded: true,
                });
                replaceCommentLocally({
                  ...selectedComment,
                  replyCounter:
                    parseInt(selectedComment?.replyCounter || 1, 10) + 1,
                });
                if (selectedComment?.replyTo?.id) {
                  replaceCommentLocally({
                    ...getCommentLocally(selectedComment.replyTo.id),
                    replyCounter:
                      parseInt(selectedComment?.replyCounter || 1, 10) + 1,
                  });
                }
              }
              resetRichText(true);
              // increase post comments counter
              setFeedCommentCounter(commentCountRef.current + 1);
              setPreventSubmit(() => false);

              setTimeout(() => {
                focusAndScroll(
                  document.querySelector(`[data-id="${data?.id}"]`)
                );
                highlightComment({
                  commentId: data?.id,
                  isReply: isReplyOrIsReplyTo,
                });
              }, 300);
            },
            onError() {
              setPreventSubmit(() => false);
            },
            onSettled: () => {
              setIsLoading(false);
            },
          }
        );
      }
    }
  };

  function resetRichText(newRichText = false) {
    setIsSendDisabled(true);
    setIsFocused(false);
    setTimeout(() => {
      textRef.current = '';
      if (newRichText) setRichTextDefaultKey((prev) => prev + 1);
    }, 300);
  }

  function handleEsc() {
    flushSync(() => {
      feedDispatch({
        type: 'TOGGLE_COMMENT_TO_EDIT',
        payload: { comment: null },
      });
    });
    setIsFocused(false);
  }

  useEscPress(handleEsc);

  const replyCommentMention = useMemo(
    () =>
      isEdit
        ? undefined
        : selectedComment
          ? selectedComment.ownerUserType === APP_ENTITIES.page
            ? {
                id: selectedComment.ownerPageInfo.id,
                username: selectedComment.ownerPageInfo?.username,
                fullName: selectedComment.ownerPageInfo?.fullName,
              }
            : {
                id: selectedComment.ownerProfileInfo.userId,
                username: selectedComment.ownerProfileInfo?.username,
                fullName: selectedComment.ownerProfileInfo?.fullName,
              }
          : undefined,
    [selectedComment, isEdit]
  );

  const handleClickOnRemoveReply = () => {
    dispatch({
      type: 'SELECT_COMMENT_TO_REPLY',
      payload: { comment: null, ref: null },
    });
  };

  const { fullName } = replyCommentMention || {};
  const isReplyTo = Boolean(fullName);
  const isReplyOrIsReplyTo = isReply || isReplyTo;

  const setRef = React.useMemo(
    () =>
      debounce((rf: Focus) => {
        quillRef.current = rf?.quill;
        if (rf?.focus && setRichTextRef) {
          setRichTextRef(rf);
        }
      }, 100),
    [setRichTextRef]
  );

  const initaillyFocused = isEdit || isReplyTo || isReplyOrIsReplyTo;
  const initialAnimationState = initaillyFocused ? 'visible' : 'hidden';

  const [isFocused, setIsFocused] = useFocusWithin(
    inputWrapperRef,
    !!initaillyFocused
  );

  const isInputOpen = !!textRef.current || isFocused;

  useEffect(() => {
    if (!inputWrapperRef.current) return;
    inputWrapperRef.current.style.overflow = 'hidden';
    setTimeout(() => {
      if (!inputWrapperRef.current) return;
      inputWrapperRef.current.style.overflow = 'visible';
    }, 300);
  }, [isInputOpen]);

  const onEmojiSelect = (value) => {
    const quill = quillRef?.current;
    const range = quill.getSelection(true);
    if (range) {
      quill.deleteText(range.index, range.length);
    }
    quill.insertText(range ? range.index : 0, value.native);
  };

  return (
    <Flex
      className={cnj(
        classes.container,
        variant === 'light-box' &&
          !isReply &&
          (!isEdit || isTabletAndLess) &&
          classes.stickBottom,
        isTabletAndLess && classes.stickBottom_tabletAndLess,
        isReplyOrIsReplyTo && classes.stickBottom_reply_to,
        isEdit && classes.stickBottom_edit,
        !isTabletAndLess && isReplyOrIsReplyTo && classes.containerNoPadding,
        classNames?.wrapperClassName
      )}
      ref={ref}
    >
      {isReplyTo && isTabletAndLess && (
        <Flex
          flexDir="row"
          className={cnj(classes.replyTo, isDark && classes.replyTo_darkp)}
        >
          <Typography
            color="disabledGrayDark_gray"
            variant="span"
            noWrap
            size={15}
            height={17}
          >
            {t('replying_to')}
          </Typography>
          <Typography
            font="bold"
            mr="auto"
            className={classes.replyToName}
            isTruncated
            size={15}
            height={17}
          >
            &nbsp;{fullName}
          </Typography>
          <IconButton
            size="sm"
            name="times"
            type="fas"
            colorSchema="secondary-transparent"
            onClick={handleClickOnRemoveReply}
          />
        </Flex>
      )}
      {isEdit && isTabletAndLess && (
        <Flex
          flexDir="row"
          className={cnj(classes.replyTo, isDark && classes.replyTo_darkp)}
        >
          <Typography
            color="disabledGrayDark_gray"
            variant="span"
            noWrap
            size={15}
            height={17}
          >
            {t('editing_comment')}
          </Typography>
          <IconButton
            size="sm"
            name="times"
            type="fas"
            colorSchema="secondary-transparent"
            onClick={handleEsc}
          />
        </Flex>
      )}
      <Flex flexDir="row" className={classes.align}>
        <Flex className={classes.avatar}>
          <ObjectLink enableHover objectId={authId}>
            <Avatar
              isCompany={isBusinessApp}
              imgSrc={avatarImage}
              size={avatarSize}
            />
          </ObjectLink>
        </Flex>
        <Flex className={classes.inputContainerWrapper}>
          <Flex className={classes.inputContainer} ref={inputWrapperRef}>
            <Flex className={classes.richTextContainer}>
              <form id={selectedComment?.id} style={{ flex: 1 }}>
                <RichText
                  ref={setRef}
                  key={richTextDefaultKey}
                  label={isReply ? t('write_a_r') : t('writ_a_c')}
                  variant="comment-input"
                  onChange={handleChange}
                  value={isEdit ? selectedComment?.body : ''}
                  businessPageId={businessPage?.id}
                  replyMention={replyCommentMention}
                  changeWithDebounce={false}
                  fixMentionDropdown={false}
                  mentionDropDownPosition={
                    variant === 'light-box' ? 'top' : undefined
                  }
                  spaceFromEmoji
                  className={cnj(
                    classes.richText,
                    isTabletAndLess && classes.richTextMobile,
                    replyCommentMention && classes.richTextWithReply
                  )}
                  noBorder
                  showEmoji={!isTabletAndLess && !isInputOpen}
                  emojiPickerPosition={
                    variant === 'search-view' ? 'positionRight' : 'default'
                  }
                />
              </form>
            </Flex>

            {!isTabletAndLess && (
              <motion.div
                animate={isInputOpen ? 'visible' : 'hidden'}
                variants={{
                  hidden: { opacity: 0, height: 0 },
                  visible: { opacity: 1, height: 'auto' },
                }}
                transition={{ duration: 0.3 }}
                initial={initialAnimationState}
              >
                <Flex className={classes.commentActions}>
                  <EmojiPickerButton
                    onEmojiSelect={onEmojiSelect}
                    emojiPickerPosition="default"
                  />
                  {isLoading ? (
                    <Spinner className={classes.spinner} />
                  ) : (
                    <IconButton
                      colorSchema="tertiary-transparent"
                      className={classes.sendButton}
                      size="sm16"
                      name="send"
                      type="far"
                      onClick={createComment}
                      disabled={isSendDisabled}
                    />
                  )}
                </Flex>
              </motion.div>
            )}
          </Flex>
          {isEdit && !isTabletAndLess && (
            <Flex flexDir="row" className={classes.editHelperContainer}>
              <Typography color="primaryText" size={13} height={15} mr={4}>
                {t('press_es_t')}
              </Typography>
              <BaseButton
                onClick={handleEsc}
                className={classes.cancelEditButton}
              >
                <Typography color="brand" size={13} height={15}>
                  {t('cancel_lower')}
                </Typography>
              </BaseButton>
            </Flex>
          )}
        </Flex>
        {isTabletAndLess && (
          <>
            {isLoading ? (
              <Spinner className={classes.spinner} />
            ) : (
              <IconButton
                colorSchema="tertiary-transparent"
                className={classes.sendButtonMobile}
                size="md"
                name="send"
                type="far"
                onClick={createComment}
                disabled={isSendDisabled}
              />
            )}
          </>
        )}
      </Flex>
      {!!urlInComment && (
        <LinkPreview
          skeletonClassName={classes.linkPreviewWrapper}
          className={classes.linkPreviewWrapper}
          isCloseable
          variant="comment"
          url={urlInComment}
        />
      )}
    </Flex>
  );
};

export default forwardRef<any, any>(CommentsInput);
