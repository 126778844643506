import React from 'react';
import Flex from '@lobox/uikit/Flex';
import useMedia from '@lobox/uikit/utils/useMedia';
import List from './Comments.list';
import Input from './Comments.input';
import useFeedElement from '../../Context/useFeedElement';
import {
  useFeedDispatch,
  useFeedState,
} from '../../Context/feedElement.provider';
import cnj from '@lobox/uikit/utils/cnj';
import classes from './Comments.component.module.scss';
import EditComment from './EditComment';

interface ICommentsProps {
  setFeedCommentCounter?: (count: number) => void;
  reactionsRef?: React.RefObject<HTMLElement>;
  containerRef?: React.RefObject<HTMLElement>;
  classNames?: {
    inputWrapper?: string;
  };
}

const Comments = ({
  setFeedCommentCounter,
  reactionsRef,
  containerRef,
  classNames,
}: ICommentsProps): JSX.Element => {
  const richTextRef = React.useRef<{ focus: Function }>();
  const selectedComment = useFeedState('selectedComment');
  const dispatch = useFeedDispatch();
  const { variant } = useFeedElement();
  const { isTabletAndLess } = useMedia();

  const handleSuccessReply = () => {
    dispatch({
      type: 'SELECT_COMMENT_TO_REPLY',
      payload: { comment: null, ref: null },
    });
  };

  const setRichTextRef = React.useCallback(
    (ref) => {
      richTextRef.current = ref;
    },
    [isTabletAndLess]
  );

  const handleClickOnMakeComment = React.useCallback(() => {
    if (!reactionsRef?.current) {
      return;
    }
    reactionsRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [reactionsRef]);

  const commentToEdit = useFeedState('commentToEdit');

  const closeEditMode = () => {
    dispatch({
      type: 'TOGGLE_COMMENT_TO_EDIT',
      payload: { comment: undefined },
    });
  };

  return (
    <Flex
      className={cnj(
        classes.container,
        variant === 'light-box'
          ? Boolean(selectedComment?.comment)
            ? classes.container_light_selectedComment
            : classes.container_light
          : undefined
      )}
    >
      {commentToEdit && isTabletAndLess ? (
        <EditComment
          selectedComment={commentToEdit}
          onEditSuccess={closeEditMode}
          avatarSize={commentToEdit?.isReply && !isTabletAndLess ? 'xs' : 'smd'}
          classNames={{ wrapperClassName: classNames?.inputWrapper }}
        />
      ) : isTabletAndLess && selectedComment?.comment ? (
        <Input
          key={selectedComment?.comment?.id}
          containerRef={containerRef}
          selectedComment={selectedComment?.comment}
          setRichTextRef={setRichTextRef}
          onReplySuccess={handleSuccessReply}
          setFeedCommentCounter={setFeedCommentCounter}
          avatarSize="smd"
          classNames={{ wrapperClassName: classNames?.inputWrapper }}
        />
      ) : (
        <Input
          containerRef={containerRef}
          setRichTextRef={setRichTextRef}
          setFeedCommentCounter={setFeedCommentCounter}
          avatarSize="smd"
          classNames={{ wrapperClassName: classNames?.inputWrapper }}
        />
      )}
      <List
        setFeedCommentCounter={setFeedCommentCounter}
        onClickOnMakeComment={handleClickOnMakeComment}
      />
    </Flex>
  );
};

export default Comments;
