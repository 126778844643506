import classes from './Entity.profile.module.scss';

import React from 'react';
import { UnitSectionItemWithData } from '@shared/components/molecules/EntityPopper/UnitSectionItemWithData';
import type { UserType } from '@lobox/utils';

interface SharedPageProps {
  profile: UserType;
}

const SharedProfile = ({ profile }: SharedPageProps): JSX.Element => {
  const { username } = profile || {};
  return (
    <UnitSectionItemWithData
      hideActions
      username={username}
      className={classes.wrapper}
    />
  );
};

export default SharedProfile;
