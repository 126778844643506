import React from 'react';
import isString from 'lodash/isString';
import { dateFromNow, useTranslation } from '@lobox/utils';
import Typography from '../Typography';
import type { TypographyProps } from '../Typography';

interface DateViewProps extends Partial<TypographyProps> {
  value: Date;
  shortFormat?: boolean;
  withTypography?: boolean;
}

const DateView = ({
  value,
  shortFormat = false,
  ...rest
}: DateViewProps): JSX.Element | null => {
  const { t } = useTranslation();

  if (!value || !isString(value)) {
    return null;
  }
  const displayValue = dateFromNow(value, shortFormat, t);

  return <Typography {...rest}>{displayValue}</Typography>;
};

export default DateView;
