import React, { useEffect, useRef } from 'react';
import Box from '@lobox/uikit/Layout/Box';
import Button from '@lobox/uikit/Button';
import ListItem from '@lobox/uikit/ListItem';
import useTheme from '@lobox/uikit/utils/useTheme';
import isFunction from 'lodash/isFunction';
import classes from './ActionBase.component.module.scss';

interface MenuActionBaseProps {
  icon?: string;
  label?: string;
  secondaryLabel?: string;
  undoClicked?: () => void;
  actionCall?: () => void;
}

const MenuActionBase = ({
  icon,
  label,
  secondaryLabel,
  undoClicked,
  actionCall,
  ...rest
}: MenuActionBaseProps): JSX.Element => {
  const undoClick = useRef(false);
  const { isDark } = useTheme();

  useEffect(() => {
    const handleActionCall = () => {
      if (!undoClick.current && isFunction(actionCall)) {
        actionCall();
      }
    };

    window.onbeforeunload = handleActionCall;
    return handleActionCall;
  }, []); // eslint-disable-line

  return (
    <Box padding={0} className={classes.container}>
      <ListItem
        className={classes.listItem}
        leftIcon={icon}
        leftColor="thirdText"
        leftType="fas"
        label={label}
        labelFont="bold"
        secondaryLabel={secondaryLabel}
        labelSize={20}
        labelColor="thirdText"
        secondaryLabelSize={15}
        secondaryLabelColor="secondaryDisabledText"
        secondaryLabelClassName={classes.secondaryLabel}
        labelsContainerClassName={classes.textContainer}
        labelTruncated
        secondaryLabelTruncated
        action={() => (
          <Button
            schema={!isDark ? 'semi-transparent' : 'secondary-dark'}
            rightIcon="undo-alt"
            label="Undo"
            onClick={() => {
              undoClick.current = true;
              undoClicked?.();
            }}
            className={classes.action}
          />
        )}
        hover={false}
        {...rest}
      />
    </Box>
  );
};

export default MenuActionBase;
