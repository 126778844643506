import classes from './FeedCard.skeleton.module.scss';

import React from 'react';
import Box from '@lobox/uikit/Layout/Box';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Skeleton from '@lobox/uikit/Skeleton';

interface FeedCardSkeleton {
  className?: string;
}

const FeedCardSkeleton: React.FC<FeedCardSkeleton> = ({ className }) => {
  return (
    <Box
      radius={12}
      padding={0}
      className={cnj(classes.feedCardSkeletonRoot, className)}
    >
      <Flex flexDir="row" className={classes.header}>
        <Skeleton className={classes.avatar} />
        <Flex className={classes.userInfo}>
          <Skeleton className={classes.username} />
          <Skeleton className={classes.date} />
        </Flex>
      </Flex>
      <Flex className={classes.descContainer}>
        {[1, 2, 3].map((x: number) => (
          <Skeleton key={x} className={classes.desc} />
        ))}
      </Flex>
      <Flex className={classes.mediaContainer}>
        <Skeleton className={classes.media} />
      </Flex>
      <Flex flexDir="row" className={classes.actionsContainer}>
        {[1, 2, 3].map((x: number) => (
          <Skeleton key={x} className={classes.action} />
        ))}
      </Flex>
    </Box>
  );
};

export default FeedCardSkeleton;
