import useGetRole from '@shared/hooks/useGetRole';
import hasPermission from '@shared/utils/hasPermission';
import PERMISSIONS from '@shared/contexts/permission-maps';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import type { ScopeType } from '@shared/types/permission';
import { useMemo } from 'react';

const useHasPermission = (scopes: Array<ScopeType>) => {
  const { roles } = useGetRole();

  const permissions = useMemo(() => getPermission(roles), [roles?.join('')]);
  // only business app has role and permission;
  const permissionGranted = useMemo(
    () => hasPermission({ permissions, scopes }),
    [permissions?.join(''), scopes?.join('')]
  );

  if (!isBusinessApp && !roles?.length) {
    return true;
  }

  return permissionGranted;
};

function getPermission(roles: ReturnType<typeof useGetRole>['roles'][]) {
  return roles.reduce((prev, curr) => [...prev, ...PERMISSIONS[curr]], []);
}

export default useHasPermission;
