type Args = {
  commentId: string;
  delayCheck?: number;
  highLightTime?: number;
  isReply?: boolean;
};
export function highlightComment({
  commentId,
  delayCheck = 300,
  highLightTime = 1500,
  isReply = false,
}: Args) {
  setTimeout(() => {
    const comment = document.querySelector(
      `[data-id="${commentId}"]`
    ) as HTMLElement;
    if (!comment) return;
    comment.classList.add('highlightedComment');
    if (isReply) comment.classList.add('highlightedCommentReplyVariant');
    setTimeout(() => {
      comment.classList.remove('highlightedComment');
      if (isReply) comment.classList.remove('highlightedCommentReplyVariant');
    }, highLightTime);
  }, delayCheck);
}
