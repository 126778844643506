import React, { createContext, useRef } from 'react';
import useForceUpdate from '@lobox/uikit/utils/useForceUpdate';
import { useQueryClient } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { Cookies, getCookieKey, QueryKeys } from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';

export const ProfileContext = createContext<any>(undefined);

interface ProfileProviderProps {
  children?: ReactNode;
}

const ProfileProvider: React.FC<ProfileProviderProps> = ({
  children,
}: React.PropsWithChildren) => {
  const pageCreationFormData = useRef<any>();
  const forceUpdate = useForceUpdate();
  const queryClient = useQueryClient();
  const { authUser } = useGetAppObject();
  const username = authUser?.username;

  const getPageCreationFormValue = (key: string) =>
    get(pageCreationFormData?.current, key);

  const setPageCreationFormData = (formData: {}) => {
    if (!isEqual(formData, pageCreationFormData.current)) {
      pageCreationFormData.current = formData;
      forceUpdate();
    }
  };

  const reFetchAboutSectionQuery = (updatedUsername?: string) =>
    queryClient.refetchQueries([
      QueryKeys.aboutSections,
      updatedUsername || username,
    ]);
  const reFetchAuthUserQuery = async () => {
    const AUTH_USER = getCookieKey('authUser');
    Cookies.remove(AUTH_USER);
    await queryClient.refetchQueries([QueryKeys.authUser]);
  };
  const reFetchBusinessPageQuery = async () => {
    const BUSINESS_PAGE = getCookieKey('businessPage');

    await queryClient.refetchQueries([QueryKeys.businessPage]);
    Cookies.remove(BUSINESS_PAGE);
  };

  return (
    <ProfileContext.Provider
      value={{
        reFetchAboutSectionQuery,
        reFetchAuthUserQuery,
        reFetchBusinessPageQuery,
        setPageCreationFormData,
        getPageCreationFormValue,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
