const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document?.createElement?.('textarea');
  textArea.value = text;
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.width = '0';
  textArea.style.height = '0';
  textArea.style.zIndex = '-10';
  textArea.style.position = 'fixed';

  document?.body?.appendChild?.(textArea);
  textArea?.focus();
  textArea?.select();

  try {
    const successful = document?.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document?.body?.removeChild?.(textArea);
};

const copyTextToClipboard = async (text: string): Promise<any> => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  try {
    await navigator?.clipboard?.writeText?.(text);
  } catch (e) {
    throw e;
  }
};

export default copyTextToClipboard;
