import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function ShareActionBottom(props: any) {
  const { theme } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      {...props}
    >
      <title>pause-s</title>
      <g id="icomoon-ignore"></g>
      <path
        fill="none"
        stroke={theme.colors.primaryText}
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="35.0001"
        d="M312.548 76.079c-16.945-12.102-40.481 0.009-40.481 20.831v46.654c0 0-257.067 0-257.067 321.332 0 0 32.133-192.798 257.067-192.798v46.656c0 20.82 23.536 32.935 40.481 20.83l126.124-90.089c28.587-20.419 28.587-62.906 0-83.326l-126.124-90.089z"
      />
    </svg>
  );
}

export default ShareActionBottom;
