import React from 'react';
import SharedAvailability from '@shared/components/Organism/EntityView/Entity.availability';
import SharedMeeting from '@shared/components/Organism/EntityView/Entity.Meeting';
import ShareTask from '@shared/components/Organism/EntityView/Entity.Task';
import SharedPost from '../../../EntityView/Entity.post';
import SharedPage from '../../../EntityView/Entity.page';
import SharedJob from '../../../EntityView/Entity.job';
import SharedProfile from '../../../EntityView/Entity.profile';
import { Event } from '@/shared/types/schedules';
import SharedBirthday from '@shared/components/Organism/EntityView/Entity.birthday';
import SharedHoliday from '@shared/components/Organism/EntityView/Entity.holiday';
import Flex from '@shared/uikit/Flex';

export interface CreateAttachmentPostProps {
  entityType:
    | 'post'
    | 'resume'
    | 'job'
    | 'profile'
    | 'page'
    | 'availability'
    | Event;
  entityData: any;
  hideActionButtons?: boolean;
  classNames?: {
    attachmentWrapper?: string;
  };
  [x: string]: any;
}

const CreateAttachmentPost: React.FC<CreateAttachmentPostProps> = ({
  entityData,
  entityType,
  ...rest
}) => {
  const result = {
    resume: null,
    task: <ShareTask event={entityData} {...rest} />,
    meeting: <SharedMeeting event={entityData} {...rest} />,
    job: <SharedJob job={entityData} {...rest} />,
    page: <SharedPage page={entityData} {...rest} />,
    post: <SharedPost selectedPost={entityData} {...rest} />,
    profile: <SharedProfile profile={entityData} {...rest} />,
    availability: <SharedAvailability entityData={entityData} {...rest} />,
    holiday: <SharedHoliday event={entityData} {...rest} />,
    birthday: <SharedBirthday event={entityData} {...rest} />,
  }[entityType?.toLowerCase()];

  return (
    <Flex className={rest?.classNames?.attachmentWrapper}>
      {result || null}
    </Flex>
  );
};
export default CreateAttachmentPost;
