import { useProfileModals, useProfilePage } from '@shared/hooks';
import { PAGE_ROLES } from '@lobox/utils';

const useVisibleProfileSectionActionButton = (): boolean => {
  const { isAuthUser, hasMembership, roles } = useProfilePage();
  const { modals } = useProfileModals();

  const canEditProfile =
    roles.includes(PAGE_ROLES.ADMIN.value) ||
    roles.includes(PAGE_ROLES.EDITOR.value);

  const visibleActionButton =
    (isAuthUser && !modals.selectedForEdit) ||
    (hasMembership && canEditProfile);

  return visibleActionButton;
};

export default useVisibleProfileSectionActionButton;
