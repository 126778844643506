import classes from './Entity.post.module.scss';

import React from 'react';
import FeedCard from '../FeedCard';
import type { IFeedElement } from '@lobox/utils';

const SharedPost = ({ selectedPost }: { selectedPost: IFeedElement }) => {
  return (
    <FeedCard
      feedElement={selectedPost}
      variant="attachment-view"
      className={classes.wrapper}
    />
  );
};

export default SharedPost;
