export const initialState = {};

type ActionType = {
  type: 'HIDE_POST';
};

type StateType = typeof initialState;

export function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'HIDE_POST':
      return {
        ...state,
        isTempHidden: true,
      };
    default: {
      throw new Error(`Unsupported action type at APP Reducer`);
    }
  }
}
