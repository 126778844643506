import { useQueryClient } from '@tanstack/react-query';
import get from 'lodash/get';
import { APP_ENTITIES, useParams } from '@lobox/utils';
import {
  privacyValues,
  QueryKeys,
  useAuthState,
  useAuthUser,
  useGetObjectDetail,
  useIsPageCreation,
} from '@lobox/utils';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import { followStatus } from '@shared/constants/enums';
import React from 'react';
import type {
  UserType,
  NetworkModelType,
  PublicSettingType,
  RoleType,
  PrivacyValueType,
} from '@lobox/utils';
import useProfile from './useProfile';
import useGetAppObject from './useGetAppObject';

type UpdaterType = (arg: UserType) => UserType;

interface UseProfilePageProps {
  isPage: boolean;
  isPageCreation: boolean;
  isMyPage: boolean;
  hasMembership: boolean;
  reFetchPageDetail: (pageUsername?: string) => void;
  refetchObjectDetail: () => void;
  objectDetail: { [key: string]: any };
  isLoading: boolean;
  isBusinessApp: boolean;
  network?: NetworkModelType;
  roles: Array<RoleType>;
  getObjectProp: <T = any>({
    pageKey,
    userKey,
  }: {
    pageKey?: Array<string> | string;
    userKey?: Array<string> | string;
  }) => T | undefined;
  authUser: any;
  isAuthUser: boolean;
  isAuthBusinessPage: boolean;
  updateObjectDetailQueryCache: (updater: UpdaterType) => UserType;
  checkSectionVisibility: (key: keyof PublicSettingType) => boolean;
  checkPrivacy: (value: {
    privacy: PrivacyValueType;
    value: string;
  }) => boolean;
  isPrivateAccount: boolean;
}

const useProfilePage = (): UseProfilePageProps => {
  const params = useParams<any>();
  const username = params?.username;
  const { businessPage, authUser } = useGetAppObject();
  const isPageCreation = useIsPageCreation();
  const {
    data: objectDetail,
    isLoading,
    refetch: refetchObjectDetail,
  } = useGetObjectDetail({
    enabled: !isPageCreation,
  });
  const myMemberships = objectDetail?.myMemberships || [];
  const queryClient = useQueryClient();
  const { getPageCreationFormValue } = useProfile();
  const isLoggedIn = useAuthState('isLoggedIn');
  const { data: user } = useAuthUser();
  const getObjectProp = ({ pageKey, userKey }: any) =>
    isPageCreation
      ? getPageCreationFormValue(pageKey)
      : objectDetail?.type === APP_ENTITIES.person
        ? get(objectDetail, userKey)
        : objectDetail?.type === APP_ENTITIES.page
          ? get(objectDetail, pageKey)
          : null;
  const isPage = objectDetail?.type === APP_ENTITIES.page;
  const network = objectDetail?.network;

  const reFetchPageDetail = (pageUsername?: string) => {
    refetchObjectDetail();
    queryClient.refetchQueries([
      QueryKeys.objectDetail,
      pageUsername || objectDetail?.username,
    ]);
  };

  const updateObjectDetailQueryCache = (updater: UpdaterType) =>
    queryClient.setQueryData(
      [QueryKeys.objectDetail, `${objectDetail?.username}`],
      (oldData: any) => updater(oldData)
    );

  const isMyPage = React.useMemo(
    () => myMemberships?.find((item) => item.userId === user?.id),
    [myMemberships, user?.id]
  );
  const hasMembership = React.useMemo(
    () => myMemberships?.find((item) => item.status === 'ACCEPTED'),
    [myMemberships]
  );
  const roles = React.useMemo(
    () => myMemberships?.map((item) => item.role),
    [myMemberships]
  );
  const isAuthUser = !isBusinessApp && authUser?.username === username;
  const isAuthBusinessPage =
    isBusinessApp && businessPage?.username === username;

  const isPrivateAccount =
    !isAuthUser &&
    objectDetail?.privateProfile &&
    (!objectDetail?.network?.follow ||
      (objectDetail?.network?.follow &&
        objectDetail?.network?.followStatus !== 'ACCEPTED'));

  const checkSectionVisibility = (key?: keyof PublicSettingType): boolean => {
    if (isLoggedIn) {
      return true;
    }
    return !!objectDetail?.publicSetting?.[key];
  };
  const checkPrivacy = (field: {
    privacy: PrivacyValueType;
    value: string;
  }) => {
    const privacy =
      field?.value &&
      (field.privacy === privacyValues.EVERYONE_AT_LOBOX ||
        (field.privacy === privacyValues.MY_FOLLOWERS &&
          objectDetail.network.back &&
          objectDetail.network.followStatus === followStatus.ACCEPTED));

    return privacy;
  };

  return {
    isBusinessApp,
    getObjectProp,
    isPage,
    isPageCreation,
    objectDetail,
    reFetchPageDetail,
    isMyPage,
    isLoading,
    roles,
    hasMembership,
    network,
    isAuthUser,
    isAuthBusinessPage,
    authUser,
    updateObjectDetailQueryCache,
    checkSectionVisibility,
    isPrivateAccount,
    checkPrivacy,
    refetchObjectDetail,
  };
};
export default useProfilePage;
