import React from 'react';
import { useTranslation } from '@lobox/utils';
import Flex from '@lobox/uikit/Flex';
import Spinner from '@lobox/uikit/Spinner';
import isNumber from 'lodash/isNumber';
import More from './Comment.more';
import classes from './Comment.showMore.module.scss';

export interface ShowMoreRepliesProps {
  hasNextPage: boolean;
  totalNumber?: number;
  isLoading?: boolean;
  hideTotalNumber?: boolean;
  handleMoreReplyClicked: () => void;
}

const ShowMoreReplies = ({
  isLoading,
  totalNumber,
  hideTotalNumber = true,
  hasNextPage,
  handleMoreReplyClicked,
}: ShowMoreRepliesProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Flex className={classes.moreRepliesSpinner}>
        <Spinner />
      </Flex>
    );
  }

  if ((isNumber(totalNumber) && !totalNumber) || !hasNextPage) {
    return null;
  }

  const labelWithTotalNumber =
    totalNumber === 1 ? t('reply_lower') : t('replies_lower');

  return (
    <More
      label={hideTotalNumber ? t('more_replies') : labelWithTotalNumber}
      onMoreReplyClick={handleMoreReplyClicked}
    />
  );
};

export default ShowMoreReplies;
