import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Reaction from './Action.reactions';
import Comment from './Action.comment';
import Share from './Action.share';
import useFeedElement from '../../Context/useFeedElement';
import { useFeedState } from '../../Context/feedElement.provider';
import classes from './FeedActions.component.module.scss';
import cnj from '@lobox/uikit/utils/cnj';

const PostFeedLayoutActions: React.FC = () => {
  const boxRef = React.useRef<HTMLElement>();
  const isOpenComments = true;
  const {
    isDeletedOriginalPost,
    isUserPost,
    post,
    isMyPost,
    getPostOwnerInfo,
  } = useFeedElement();
  const commentToEdit = useFeedState('commentToEdit');

  const allowOthersToShareYourPosts = getPostOwnerInfo({
    userKey: 'allowOthersToShareYourPosts',
    pageKey: 'allowOthersToShareYourPosts',
  });

  React.useEffect(() => {
    if (commentToEdit) {
      boxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [commentToEdit]);

  return (
    <Flex
      ref={boxRef}
      flexDir="row"
      className={cnj(
        classes.container,
        isOpenComments && classes.container_open
      )}
    >
      <Reaction />
      <Comment />
      {isDeletedOriginalPost ||
      (!allowOthersToShareYourPosts && !isMyPost) ||
      (isUserPost &&
        post.ownerProfileInfo?.privateProfile &&
        !isMyPost) ? null : (
        <Share />
      )}
    </Flex>
  );
};

export default PostFeedLayoutActions;
