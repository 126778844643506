import React from 'react';
import { useParams, usePathname } from 'next/navigation';

import { useGlobalDispatch, useGlobalState } from '@lobox/utils';
import { routeNames } from '@shared/utils-pkg/constants/routeNames';

export const calculateBackCount = (
  routes: string[],
  lastPathname: React.MutableRefObject<string>,
  dispatcher: () => void
) => {
  if (
    routes.includes(window.location.pathname) &&
    window.location.pathname !== lastPathname.current
  ) {
    lastPathname.current = window.location.pathname;
    dispatcher();
  }

  window.history.pushState = new Proxy(window.history.pushState, {
    apply: (
      target,
      thisArg,
      argArray: [data: any, unset: string, url?: string]
    ) => {
      target.apply(thisArg, argArray);

      if (
        routes.includes(window.location.pathname) &&
        window.location.pathname !== lastPathname.current
      ) {
        lastPathname.current = window.location.pathname;
        dispatcher();
      }
    },
  });
};

const searchMainPathnames = [routeNames.search, routeNames.searchAll];
const searchSubPathnames = [
  routeNames.searchPeople,
  routeNames.searchPages,
  routeNames.searchJobs,
  routeNames.searchGroups,
  routeNames.searchPosts,
  routeNames.searchHashtags,
];
const searchPathnames = [...searchMainPathnames, ...searchSubPathnames];
const scheduleMainRoutes = [
  routeNames.schedulesCalendar,
  routeNames.schedulesCalendarYear,
  routeNames.schedulesCalendarMonth,
  routeNames.schedulesCalendarWeek,
  routeNames.schedulesCalendarDay,
];
const scheduleSubPathnames = [
  routeNames.schedulesMeetings,
  routeNames.schedulesEvents,
  routeNames.schedulesTasks,
  routeNames.schedulesReminders,
  routeNames.createSchedule,
  routeNames.schedulesAvailability,
];
const schedulePathnames = [...scheduleMainRoutes, ...scheduleSubPathnames];
const profilePathnames = [
  '',
  routeNames.dashboard,
  routeNames.feed,
  routeNames.profilePages,
  routeNames.jobs,
  routeNames.hashtags,
  routeNames.collections,
  routeNames.schedules,
];

const useBackToLastPage = () => {
  const lastPathname = React.useRef('');
  const lastProfilePathname = React.useRef('');
  const lastSchedulePathname = React.useRef('');
  const lastSearchPathname = React.useRef('');

  const globalDispatch = useGlobalDispatch();
  const pathname = usePathname();
  const params = useParams();
  const profileHistoryCount = useGlobalState('profileHistoryCount');
  const scheduleHistoryCount = useGlobalState('scheduleHistoryCount');
  const searchHistoryCount = useGlobalState('searchHistoryCount');
  const isSearchStartedFromMain = useGlobalState('isSearchStartedFromMain');

  // for profile

  React.useEffect(() => {
    if (!params?.username) return;

    calculateBackCount(
      profilePathnames.map((path) => `/${params?.username}${path}`),
      lastProfilePathname,
      () => globalDispatch({ type: 'ADD_PROFILE_HISTORY_COUNT' })
    );
  }, [params?.username]);

  React.useEffect(() => {
    if (!profileHistoryCount) {
      lastProfilePathname.current = '';
    }
  }, [profileHistoryCount]);

  // for schedule

  React.useEffect(() => {
    calculateBackCount(schedulePathnames, lastSchedulePathname, () =>
      globalDispatch({ type: 'ADD_SCHEDULE_HISTORY_COUNT' })
    );
  }, []);

  React.useEffect(() => {
    if (!scheduleHistoryCount) {
      lastSchedulePathname.current = '';
    }
  }, [scheduleHistoryCount]);

  // for schedule sub-page

  React.useEffect(() => {
    if (scheduleSubPathnames.includes(pathname)) {
      globalDispatch({ type: 'SET_SCHEDULE_SUBPAGE' });
    }
  }, [pathname]);

  // for search

  React.useEffect(() => {
    calculateBackCount(searchPathnames, lastSearchPathname, () =>
      globalDispatch({ type: 'ADD_SEARCH_HISTORY_COUNT' })
    );
  }, []);

  React.useEffect(() => {
    if (!searchHistoryCount) {
      lastSearchPathname.current = '';
    }
  }, [searchHistoryCount]);

  // for search sub-page

  React.useEffect(() => {
    if (searchSubPathnames.includes(pathname)) {
      globalDispatch({ type: 'SET_SEARCH_SUBPAGE' });
    }
  }, [pathname]);

  React.useEffect(() => {
    if (
      !isSearchStartedFromMain &&
      searchMainPathnames.includes(lastPathname.current) &&
      searchSubPathnames.includes(pathname)
    ) {
      globalDispatch({ type: 'SET_SEARCH_STARTED_FROM_MAIN' });
    }
  }, [pathname, isSearchStartedFromMain]);

  React.useEffect(() => {
    lastPathname.current = pathname;
  }, [pathname]);
};

export default useBackToLastPage;
