import React from 'react';
import useObjectNetwork from '@shared/hooks/api-hook/useObjectNetwork';
import { useFeedState } from '../../Context/feedElement.provider';
import useFeedElement from '../../Context/useFeedElement';
import UnFollowNotify from '../MenuActionBaseUI';

const UnFollow = (): JSX.Element => {
  const {
    getPostOwnerInfo,
    undoUnfollow,
    isBusinessPost,
    myFollowingReactionOnPost,
  } = useFeedElement();
  const userName = getPostOwnerInfo({ userKey: 'name', pageKey: 'title' });
  const { unfollowHandler } = useObjectNetwork();

  const unFollowSender = useFeedState('unFollowSender');

  const handleUndoClicked = () => {
    undoUnfollow();
  };
  const handleUnfollow = () => {
    const id =
      unFollowSender === 'myFollowing'
        ? myFollowingReactionOnPost?.ownerId
        : getPostOwnerInfo({ userKey: 'userId', pageKey: 'id' });
    unfollowHandler({
      id,
      isPage: isBusinessPost,
      name: '',
      needsAlert: false,
      username: '',
    });
  };
  return (
    <UnFollowNotify
      icon="do-not-enter"
      label={`Unfollowed ${
        unFollowSender === 'myFollowing'
          ? myFollowingReactionOnPost.actorTitle
          : userName
      }`}
      secondaryLabel={`You will not see any post created by ${
        unFollowSender === 'myFollowing'
          ? myFollowingReactionOnPost.actorTitle
          : userName
      } in your feed.`}
      undoClicked={handleUndoClicked}
      actionCall={handleUnfollow}
    />
  );
};

export default UnFollow;
