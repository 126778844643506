import React, { DependencyList } from 'react';

const useBeforeUnload = (
  handler: (evt: Event) => void,
  dependencyList: DependencyList = []
) => {
  const handleBeforeUnload = React.useCallback((evt: Event) => {
    handler(evt);
  }, dependencyList);

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload, false);
    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnload, false);
  }, dependencyList);
};

export default useBeforeUnload;
