import useTheme from '@lobox/uikit/utils/useTheme';
import light from '../../../public/placeholders/placeholderLight.png';
import dark from '../../../public/placeholders/placeholderDark.png';

const usePlaceholder = (): string => {
  const { isDark } = useTheme();

  return isDark ? dark.src : light.src;
};

export default usePlaceholder;
