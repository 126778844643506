import { RefObject, useEffect, useState } from 'react';

export function useFocusWithin(
  ref: RefObject<HTMLElement> | undefined,
  initialValue: boolean | undefined
): ReturnType<typeof useState<boolean>> {
  const [isFocused, setIsFocused] = useState<boolean | undefined>(initialValue);

  useEffect(() => {
    if (!ref || !ref.current) return;
    const setFocus = (e: any) => {
      const passElement = ref.current?.querySelector(
        '[data-name="pass-click"]'
      );
      if (passElement?.contains(e.target)) return;
      setIsFocused(true);
    };

    ref.current.addEventListener('mousedown', setFocus);

    return () => {
      ref?.current?.removeEventListener('mousedown', setFocus);
    };
  }, []);

  return [isFocused, setIsFocused];
}
