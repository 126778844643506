import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import { PostReactions, useTranslation } from '@lobox/utils';
import ObjectLink from '@shared/components/molecules/ObjectLink';
import Menu from '../../Components/PostMenuItems';
import Divider from '../../Components/Divider';
import useFeedElement from '../../Context/useFeedElement';
import classes from './MyFollowingReactionHeader.component.module.scss';

const MyFollowingReactionHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const { myFollowingReactionOnPost } = useFeedElement();
  const type = myFollowingReactionOnPost?.type;
  const reactionText = t(
    type === PostReactions.like
      ? 'liked_this'
      : type === PostReactions.boost
      ? 'boosted_this'
      : type === PostReactions.celebrate
      ? 'celebrated_this'
      : type === PostReactions.comment
      ? 'cmnt_on_this'
      : ''
  );

  return (
    <>
      <Flex flexDir="row" className={classes.container}>
        <Flex className={classes.containerText}>
          <ObjectLink enableHover objectId={myFollowingReactionOnPost.ownerId}>
            <Typography
              mr={3}
              color="thirdText"
              size={15}
              font="bold"
              height={21}
            >
              {myFollowingReactionOnPost?.actorTitle}
            </Typography>
          </ObjectLink>
          <Typography mr={3} color="border" size={15} font="400" height={21}>
            {reactionText}
          </Typography>
        </Flex>
        <Flex className={classes.menuContainer}>
          <Menu />
        </Flex>
      </Flex>
      <Divider />
    </>
  );
};

export default MyFollowingReactionHeader;
