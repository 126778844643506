import React from 'react';
import ListItem from '@lobox/uikit/ListItem';
import classes from './Menu.item.module.scss';

interface HeaderEditButtonItemProps {
  iconName: string;
  label: string;
  secondaryLabel?: string;
  onClick?: any;
  iconType?: any;
  labelFont?: string;
  labelSize?: number;
}

const HeaderEditButtonItem = ({
  iconName,
  label,
  secondaryLabel,
  onClick,
  iconType = 'fal',
  labelSize = 15,
  labelFont,
}: HeaderEditButtonItemProps): JSX.Element => {
  return (
    <ListItem
      labelsContainerClassName={classes.labelContainerClass}
      className={classes.listItemWrapper}
      secondaryLabel={secondaryLabel}
      secondaryLabelSize={12}
      secondaryLabelColor="primaryDisabledText"
      labelProps={{ isWordWrap: true, isTruncated: true }}
      secondaryLabelProps={{ isWordWrap: true, isTruncated: true }}
      popperItem
      leftIcon={iconName}
      leftType={iconType}
      leftSize={18}
      leftColor="thirdText"
      label={label}
      labelColor="thirdText"
      labelSize={labelSize}
      labelFont={labelFont}
      hover
      hoverColor="thirdText"
      hoverBgColor="hoverPrimary"
      onClick={onClick}
    />
  );
};

export default HeaderEditButtonItem;
