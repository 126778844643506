import { getPostComments, QueryKeys, useInfiniteQuery } from '@lobox/utils';

type GetPostCommentsProps = {
  postId: string;
  size?: number;
  options?: any;
};

const useGetPostComments = ({
  postId,
  size = 3,
  options = {},
}: GetPostCommentsProps) =>
  useInfiniteQuery(
    [QueryKeys.postComments, postId],
    {
      func: getPostComments,
      size,
      extraProps: {
        postId,
      },
    },
    options
  );

export default useGetPostComments;
