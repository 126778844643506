import React, { useState } from 'react';
import reduce from 'lodash/reduce';
import Flex from '@lobox/uikit/Flex';
import useMedia from '@lobox/uikit/utils/useMedia';
import useGetCommentReplies from '@shared/hooks/api-hook/useGetCommentReplies';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';
import PureComment from '../PureComment';
import Input from '../Comments.input';
import { CommentProvider } from '../context/comment.provider';
import {
  useFeedDispatch,
  useFeedState,
} from '../../../Context/feedElement.provider';
import ReplyItem from './Comment.replyItem';
import ShowMoreReplies from './Comment.showMore';
import classes from './Comment.component.module.scss';
import EditComment from '../EditComment';
import { focusAndScroll } from '@shared/utils-pkg/utils/focusAndScroll';
import { highlightComment } from '@shared/utils-pkg/utils/highlightComment';

const refetchPlaceholder = (): null => null;
const onClickOnReplyPlaceholder = (): null => null;

const CommentsItem: React.FC<any> = ({
  data,
  replySize = 0,
  refetch: refetchComments,
  setFeedCommentCounter,
  onClickOnReply = onClickOnReplyPlaceholder,
}) => {
  const richTextRef = React.useRef<{ focus: Function }>();
  const isFirstFetch = React.useRef(true);
  const selectCommentRef = React.useRef(null);
  const recentRepliesRef = React.useRef({
    comments: [],
    ids: {},
  });
  const feedDispatch = useFeedDispatch();
  const { isTabletAndLess } = useMedia();
  const [isReplyClicked, setIsReplyClicked] = useState(false);
  const [size, setSize] = React.useState(0);
  const enabledRef = React.useRef(true);

  const {
    data: commentReplies = [],
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useGetCommentReplies({
    commentId: data?.id,
    enabled: Boolean(
      Number(data.replyCounter) && replySize && enabledRef.current
    ),
    size: size || replySize,
  });
  const refetchReplies = hasNextPage ? refetch : refetchPlaceholder;

  const reorderedData = React.useMemo(
    () => orderBy(commentReplies, 'createdDate'),
    [commentReplies]
  );

  React.useEffect(() => {
    if (replySize) {
      enabledRef.current = false;
      setSize(() => 6);
    }
  }, [replySize]);

  const setRichTextRef = React.useCallback((ref) => {
    richTextRef.current = ref;
  }, []);

  const handleReplyClicked = (selectedCom: any) => {
    setIsReplyClicked(true);
    handleReplyClickedShared(selectedCom);
  };

  const handleReplyClickedShared = (selectedCom: any) => {
    onClickOnReply();

    feedDispatch({
      type: 'SET_REPLY_CLICK_COUNTER',
      payload: 1,
    });

    if (isTabletAndLess) {
      feedDispatch({
        type: 'SELECT_COMMENT_TO_REPLY',
        payload: { comment: selectedCom, ref: selectCommentRef },
      });
    }
  };

  const handleSuccessReply = (...args) => {
    setIsReplyClicked(false);
    handleSuccessReplyShared(...args);
  };
  const handleSuccessReplyShared = (newComment: any) => {
    feedDispatch({
      type: 'SELECT_COMMENT_TO_REPLY',
      payload: { comment: null, ref: null },
    });
  };

  function handleMoreReplyClicked() {
    if (isFirstFetch.current) {
      refetchReplies();
    } else {
      fetchNextPage();
    }
    isFirstFetch.current = false;
  }

  const recentReplies = (() => {
    const replyIds = map(reorderedData, 'id');

    forEach(recentRepliesRef.current.ids, (id) => {
      if (!replyIds.includes(id)) {
        recentRepliesRef.current.comments =
          recentRepliesRef.current.comments.filter(
            ({ id: replyId }) => replyId !== id
          );
        delete recentRepliesRef.current.ids[id];
      }
    });

    return recentRepliesRef.current;
  })();

  const replies = reduce(
    reorderedData,
    (acc, reply) => {
      if (reply.recentlyAdded) {
        if (!acc.recentlyAddedIds[reply.id]) {
          acc.recentlyAdded.push(reply);
          acc.recentlyAddedIds[reply.id] = reply.id;
        } else {
          const index = findIndex(
            acc.recentlyAdded,
            (rep) => rep.id === reply.id
          );
          acc.recentlyAdded[index] = reply;
        }
      }
      if (!reply.recentlyAdded) {
        if (!acc.recentlyAddedIds[reply.id]) {
          acc.previouslyAdded.push(reply);
        } else {
          let index = findIndex(
            acc.previouslyAdded,
            (rep) => rep.id === reply.id
          );
          if (index === -1) {
            const index = findIndex(
              acc.recentlyAdded,
              (rep) => rep.id === reply.id
            );

            acc.recentlyAdded[index] = reply;
          } else {
            acc.previouslyAdded[index] = reply;
          }
        }
      }
      return acc;
    },
    {
      previouslyAdded: [],
      recentlyAdded: [...recentReplies.comments],
      recentlyAddedIds: {
        ...recentReplies.ids,
      } as any,
    }
  );

  const firstReplyId = replies.previouslyAdded[0]?.id;

  recentRepliesRef.current.comments = replies.recentlyAdded;
  recentRepliesRef.current.ids = replies.recentlyAddedIds;
  const commentToEdit = useFeedState('commentToEdit');
  const dispatch = useFeedDispatch();

  const closeEditMode = () => {
    dispatch({
      type: 'TOGGLE_COMMENT_TO_EDIT',
      payload: { comment: undefined },
    });
  };

  const isEdit = (comment: any) => comment?.id === commentToEdit?.id;

  return (
    <CommentProvider>
      {!isTabletAndLess && isEdit(data) ? (
        <EditComment
          selectedComment={commentToEdit}
          onEditSuccess={closeEditMode}
          avatarSize={commentToEdit?.isReply ? 'xs' : 'smd'}
        />
      ) : (
        <PureComment
          data={data}
          ref={selectCommentRef}
          onReplyClick={handleReplyClicked}
          setFeedCommentCounter={setFeedCommentCounter}
        />
      )}
      <Flex className={classes.replayList}>
        {isReplyClicked && !isTabletAndLess && (
          <Flex className={classes.replyInput} key={data.id}>
            <Input
              isReply
              avatarSize="xs"
              setRichTextRef={setRichTextRef}
              firstReplyId={firstReplyId}
              onReplySuccess={handleSuccessReply}
              setFeedCommentCounter={setFeedCommentCounter}
              selectedComment={data}
            />
          </Flex>
        )}
        <ShowMoreReplies
          isLoading={isFetching || isFetchingNextPage}
          hasNextPage={Boolean(replies.previouslyAdded.length) && hasNextPage}
          handleMoreReplyClicked={handleMoreReplyClicked}
        />
        {replies.previouslyAdded.map((comment: any) => (
          <ReplyItem
            key={comment.id}
            data={comment}
            refetchCommentList={refetchComments}
            refetchReplyList={refetchReplies}
            onReplyClick={handleReplyClickedShared}
            setFeedCommentCounter={setFeedCommentCounter}
            isReply
            replyProps={{
              classes,
              setRichTextRef,
              firstReplyId,
              handleSuccessReply: handleSuccessReplyShared,
            }}
          />
        ))}
        {replies.recentlyAdded.map((comment: any) => (
          <ReplyItem
            isReply
            key={comment.id}
            data={comment}
            onReplyClick={handleReplyClickedShared}
            setFeedCommentCounter={setFeedCommentCounter}
            replyProps={{
              classes,
              setRichTextRef,
              firstReplyId,
              handleSuccessReply: handleSuccessReplyShared,
            }}
          />
        ))}
      </Flex>
    </CommentProvider>
  );
};

export default CommentsItem;
