import React from 'react';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import classes from './Divider.component.module.scss';
import cnj from '@lobox/uikit/utils/cnj';

const DividerCmp: React.FC = () => {
  return (
    <Flex className={cnj(classes.divider, 'divider-cmp')}>
      <Divider />
    </Flex>
  );
};

export default DividerCmp;
