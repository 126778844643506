import React from 'react';
import { ProfileModalsContext } from '@shared/contexts/profileModals.provider';
import type { ModalStateType } from '@shared/contexts/profileModals.provider';

type ModalsType = {
  modals: ModalStateType;
  open: Function;
  goBack: () => void;
  close: () => void;
  hasPrev: Function;
  openConfirmDialog: Function;
  reFetchAboutSectionQuery: Function;
  setSelectedForEdit: Function;
};

const useProfileModals = (): ModalsType =>
  React.useContext(ProfileModalsContext) as any;

export default useProfileModals;
