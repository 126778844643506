import React from 'react';
import TempNotif from '../MenuActionBaseUI';
import { useReactMutation, hidePost } from '@lobox/utils';
import useFeedElement from '../../Context/useFeedElement';

const TempHidden = () => {
  const { mutate: _hidePost } = useReactMutation({
    apiFunc: hidePost,
  });
  const { post, undoHide } = useFeedElement();
  const handleHidden = () => {
    _hidePost({ postId: post?.id });
  };
  const handleUndoClicked = () => {
    undoHide();
  };

  return (
    <TempNotif
      icon="eye-slash"
      label="Post Hidden"
      secondaryLabel="You will not see this post anymore"
      undoClicked={handleUndoClicked}
      actionCall={handleHidden}
    />
  );
};

export default TempHidden;
