import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import IconButton from '@lobox/uikit/Button/IconButton';
import PopperMenu from '@lobox/uikit/PopperMenu';
import Flex from '@lobox/uikit/Flex';
import useToast from '@lobox/uikit/Toast/useToast';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import Spinner from '@lobox/uikit/Spinner';
import {
  useGlobalDispatch,
  useDisclosure,
  useTranslation,
  deletePost,
  useReactMutation,
  deletePostFromCollection,
  togglePostNotifications,
} from '@lobox/utils';
import useGetPostMenuItemsStatus from '@shared/hooks/api-hook/useGetPostMenuItemsStatus';
import noop from 'lodash/noop';
import useObjectNetwork from '@shared/hooks/api-hook/useObjectNetwork';
import useUnHidePost from '@shared/hooks/api-hook/useUnHidePost';
import useUrlRoute from '@shared/hooks/useUrlRoute';
import isFunction from 'lodash/isFunction';
import MenuItem from './Menu.item';
import { useFeedState } from '../../Context/feedElement.provider';
import useFeedElement from '../../Context/useFeedElement';
import classes from './PostMenuItem.component.module.scss';

const SavePostModal = dynamic(() => import('../../modals/SavePost'));

const PostHeaderMenu = (): JSX.Element => {
  const { isOpen: isOpenMenu, onClose, onOpen } = useDisclosure();
  const appDispatch = useGlobalDispatch();
  const isHiddenList = useFeedState('isHiddenList');
  const deleteCallback = useFeedState('onDeletePost');
  const unsaveCallback = useFeedState('onUnsavePost');
  const onUnHidePost = useFeedState('onUnHidePost');
  const {
    post,
    isMyPost,
    hidePost,
    unFollowPostOwner,
    ownerName,
    toggleReportModal,
    blockUser,
    isMyFollowingPostOwnerFromOtherPlaces,
    isHighlight,
    myFollowingReactionOnPost,
    follow,
    visibleNetworkInfoOnHeader,
    getPostOwnerInfo,
    isBusinessPost,
    followPostOwner,
    feedElement,
    isSharedPost,
  } = useFeedElement();

  const { followHandler } = useObjectNetwork();

  const [isSaved, toggleIsSaved] = useState(false);
  const [isOffNotification, toggleNotification] = useState(false);

  const {
    isSuccess,
    data: postMenuItemsStatus,
    isFetching,
  } = useGetPostMenuItemsStatus({
    enabled: true,
    postId: post.id,
  });

  const { isHomePage } = useUrlRoute();

  const { mutate: unsSavePost } = useReactMutation({
    apiFunc: deletePostFromCollection,
  });
  const { mutate: _deletePost } = useReactMutation({
    apiFunc: deletePost,
  });
  const { mutate: _togglePostNotifications } = useReactMutation({
    apiFunc: togglePostNotifications,
  });

  const { mutate: _unHidePost } = useUnHidePost();

  const { t } = useTranslation();
  const toast = useToast();
  const { openConfirmDialog } = useOpenConfirm();

  const { isOpen: isSavePostModalOpen, onToggle: onSavePostModalToggle } =
    useDisclosure();

  const handleDeletePost = () => {
    openConfirmDialog({
      title: 'Delete Post',
      message: 'Are you sure you want to delete this post?',
      confirmButtonText: 'Delete',
      cancelButtonText: t('confirm_cancel'),
      isAjaxCall: true,
      apiProps: {
        func: _deletePost,
        variables: { id: post.id },
        onSuccess: () => {
          if (isFunction(deleteCallback)) {
            deleteCallback(feedElement);
          }

          toast({
            type: 'success',
            icon: 'check-circle',
            message: 'Post deleted successfully',
          });
        },
        onError: noop,
      },
    });
  };
  const handleHidePost = () => {
    hidePost();
  };
  const handleUnFollowMyFollowing = () => {
    unFollowPostOwner('myFollowing');
  };
  const handleUnFollowPostOwner = () => {
    unFollowPostOwner('owner');
  };
  const handleFollow = () => {
    const id = getPostOwnerInfo({
      userKey: 'userId',
      pageKey: 'id',
    });
    followHandler(
      { id, isPage: isBusinessPost },
      {
        onSuccess: () => {
          followPostOwner();
        },
      }
    );
  };
  const handleReportClicked = () => {
    toggleReportModal({
      reportedEntityTitle: ownerName,
      entityType: 'post',
      entityId: post.id,
      onBlock: blockUser,
      onUnfollow: unFollowPostOwner,
      entityOwnerId: post.ownerId,
    });
  };

  const handleToggleNotification = (off: boolean) => {
    _togglePostNotifications(
      { postId: post.id, off },
      {
        onSuccess: () => {
          toggleNotification(off);
        },
      }
    );
  };

  const SAVE_POST = {
    id: 'savePost',
    icon: 'bookmark',
    title: t('save_post'),
    helper: t('add_this_t_y_s_po'),
    onClick: () => {
      onSavePostModalToggle();
    },
  };
  const UN_SAVE_POST = {
    id: 'unSavePost',
    icon: 'bookmark',
    title: t('unsave_post'),
    helper: t('remove_t_p_f_collec'),
    onClick: () => {
      openConfirmDialog({
        title: t('unsave_post'),
        message: t('r_y_s_y_w_t_d_p_f_co'),
        confirmButtonText: 'Unsave',
        cancelButtonText: t('confirm_cancel'),
        isAjaxCall: true,
        apiProps: {
          func: unsSavePost,
          variables: {
            // @ts-ignore
            collectionId: postMenuItemsStatus?.collection?.id,
            postId: post?.id,
          },
          onSuccess: () => {
            toggleIsSaved(false);
            unsaveCallback?.(post);
          },
        },
      });
    },
  };
  const HIDE_ACTION = {
    id: 'hidePost',
    icon: 'eye-slash',
    title: t('hide_post'),
    helper: t('hide_t_p_f_y_a'),
    onClick: handleHidePost,
  };
  const UN_HIDE_ACTION = {
    id: 'unHidePost',
    icon: 'eye',
    title: t('unhide_post'),
    helper: t('unhide_t_p_t_see_i_f'),
    onClick: () => {
      _unHidePost(
        { postId: post.id },
        {
          onSuccess: () => onUnHidePost?.(feedElement),
        }
      );
    },
  };

  const TURN_OFF_NOTIFICATION = {
    id: 'turnOFF',
    icon: 'bell-slash',
    title: t('t_of_p_notif'),
    helper: t('dnt_rec_notif_f_t_p'),
    onClick: () => handleToggleNotification(true),
  };
  const TURN_ON_NOTIFICATION = {
    id: 'turnOn',
    icon: 'bell-on',
    title: t('t_on_p_notif'),
    helper: t('receive_a_notif_f_t_p'),
    onClick: () => handleToggleNotification(false),
  };
  const myPostMenu = [
    // @ts-ignore
    (isSuccess && !!postMenuItemsStatus.collection) || isSaved
      ? UN_SAVE_POST
      : SAVE_POST,
    !isHighlight &&
      !isSharedPost && {
        id: 'editPost',
        icon: 'edit',
        title: t('edit_post'),
        helper: t('edit_t_c_y_p'),
        onClick: () => {
          appDispatch({
            type: 'SET_CREATE_POST_MODAL',
            payload: {
              currentTab: 'main',
              isOpenModal: true,
              selectedPostForEdit: post,
            },
          });
        },
      },
    // @ts-ignore
    !!postMenuItemsStatus?.turnOff?.off || isOffNotification
      ? TURN_ON_NOTIFICATION
      : TURN_OFF_NOTIFICATION,
    !isHiddenList ? (isHomePage ? HIDE_ACTION : undefined) : UN_HIDE_ACTION,
    {
      id: 'deletePost',
      icon: 'trash',
      title: t('delete_post'),
      helper: t('permanently_d_f_y_a'),
      onClick: handleDeletePost,
    },
  ].filter(Boolean);
  const myFollowersPostMenu = [
    // @ts-ignore
    (isSuccess && !!postMenuItemsStatus.collection) || isSaved
      ? UN_SAVE_POST
      : SAVE_POST,
    // @ts-ignore
    !!postMenuItemsStatus?.turnOff?.off || isOffNotification
      ? TURN_ON_NOTIFICATION
      : TURN_OFF_NOTIFICATION,
    !isHiddenList ? (isHomePage ? HIDE_ACTION : undefined) : UN_HIDE_ACTION,
    visibleNetworkInfoOnHeader &&
      myFollowingReactionOnPost && {
        id: 'unFollow',
        icon: 'window-close',
        title: `${t('unfollow')} ${myFollowingReactionOnPost.actorTitle}`,
        helper: `${t('stop_following')} ${
          myFollowingReactionOnPost.actorTitle
        }`,
        onClick: handleUnFollowMyFollowing,
      },
    myFollowingReactionOnPost?.ownerId !== post.ownerId &&
      (isMyFollowingPostOwnerFromOtherPlaces || follow) && {
        id: 'unfollow',
        icon: 'window-close',
        title: `${t('unfollow')} ${ownerName}`,
        helper: `${t('stop_following')} ${ownerName}`,
        onClick: handleUnFollowPostOwner,
      },
    (!visibleNetworkInfoOnHeader && !follow) ||
    (visibleNetworkInfoOnHeader && !myFollowingReactionOnPost && !follow)
      ? {
          id: 'follow',
          icon: 'plus-square',
          title: `${t('follow_cap')} ${ownerName}`,
          helper: `${t('following_cap')} ${ownerName}`,
          onClick: handleFollow,
        }
      : undefined,
    !postMenuItemsStatus?.memberOfPostOwnerPage && {
      id: 'report',
      icon: 'pennant',
      title: t('report_post'),
      helper: t('im_concerned_a_t_p'),
      onClick: handleReportClicked,
    },
  ].filter(Boolean);

  const menu = isMyPost ? myPostMenu : myFollowersPostMenu;

  return (
    <>
      <Flex flexDir="row" className={classes.container}>
        <PopperMenu
          placement="bottom-end"
          menuClassName={classes.menuContainer}
          onOpen={() => {
            onOpen();
          }}
          // clickCallBack={() => {
          //   onClose();
          // }}
          onClose={() => {
            onClose();
          }}
          closeOnScroll
          buttonComponent={
            <IconButton
              type="far"
              name="ellipsis-h"
              size="md"
              className={classes.moreButton}
            />
          }
        >
          {isFetching ? (
            <Spinner className={classes.spinner} />
          ) : (
            menu?.map((x: any) => (
              <MenuItem
                key={x.id}
                iconName={x.icon}
                label={x.title}
                secondaryLabel={x.helper}
                onClick={x.onClick}
                iconType="far"
                labelSize={15}
                labelFont="bold"
              />
            ))
          )}
        </PopperMenu>
      </Flex>
      {isSavePostModalOpen && (
        <SavePostModal
          onClose={(_isSuccess) => {
            if (_isSuccess) {
              toggleIsSaved(true);
            }
            onSavePostModalToggle();
          }}
        />
      )}
    </>
  );
};

export default PostHeaderMenu;
