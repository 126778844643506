import { getPostActionsStatus, QueryKeys, useReactQuery } from '@lobox/utils';
import type { UseQueryResult } from '@tanstack/react-query';
import type { Response, IPostMenu } from '@lobox/utils';

const useGetPostMenuItemsStatus = ({
  enabled,
  postId,
}: {
  enabled: boolean;
  postId: string;
}): UseQueryResult<Response<IPostMenu>> => {
  return useReactQuery({
    action: {
      apiFunc: getPostActionsStatus,
      key: [QueryKeys.postActionsStatus, postId],
      params: {
        postId,
      },
      spreadParams: true,
    },
    config: {
      enabled: !!(postId && enabled),
    },
  });
};

export default useGetPostMenuItemsStatus;
