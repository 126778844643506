import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import RichTextView from '@lobox/uikit/RichText/RichTextView';
import { useHistory } from '@lobox/utils';
import { routeNames } from '@lobox/utils';
import ObjectLink from '@shared/components/molecules/ObjectLink';
import LinkPreview from '@shared/components/Organism/SearchPosts/SearchPosts.ogLink';
import { getExternalUrls } from '@shared/utils/getUrlsFromText';
import classes from './PureComment.content.module.scss';
import useNavigateSearchPage from '@shared/hooks/useNavigateSearchPage';
import { useObjectClicks } from '@shared/hooks/useObjectClicks';

interface PureCommentContentProps {
  userName: string;
  body: string;
  edited: boolean;
  href: string;
  renderMenu?: () => ReactNode;
}

const PureCommentContent: React.VFC<PureCommentContentProps> = ({
  userName,
  body,
  edited,
  href,
  renderMenu,
}) => {
  const history = useHistory();
  const urlInComment = getExternalUrls(body)?.[0];

  const { handleTagClick, handleHashtagClick } = useObjectClicks();

  return (
    <Flex className={classes.container}>
      <Flex className={classes.text}>
        <ObjectLink className={classes.objectLink} objectId={href}>
          <Typography
            isWordWrap
            isTruncated
            lineNumber={1}
            size={16}
            font="bold"
            height={20}
            color="smoke_coal"
            className={classes.userName}
          >
            {userName}
          </Typography>
        </ObjectLink>
        <Flex>
          <Flex className={classes.textWrapper}>
            <RichTextView
              html={body}
              typographyProps={{
                size: 14,
                color: 'primaryText',
                isWordWrap: true,
              }}
              showMore
              onMentionClick={handleTagClick}
              onHashtagClick={handleHashtagClick}
              seeMoreClassName={classes.seeMoreClassName}
              row={3}
            />
          </Flex>
          {!!urlInComment && (
            <LinkPreview
              skeletonClassName={classes.linkPreviewWrapper}
              className={classes.linkPreviewWrapper}
              variant="comment"
              url={urlInComment}
            />
          )}
        </Flex>
        {edited && (
          <Typography size={12} height={14} mt={4} color="border">
            (Edited)
          </Typography>
        )}
      </Flex>
      <Flex className="commentItemMenu">{renderMenu?.()}</Flex>
    </Flex>
  );
};

export default PureCommentContent;
