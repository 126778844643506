import React from 'react';
import Button from '@lobox/uikit/Button';
import { useGlobalDispatch, useTranslation } from '@lobox/utils';
import Share from '@shared/svg/ShareActionBottom';
import useFeedElement from '../../Context/useFeedElement';
import classes from './Action.share.module.scss';

const PostFeedLayoutActions: React.FC = () => {
  const { t } = useTranslation();

  const { feedElement } = useFeedElement();
  const appDispatch = useGlobalDispatch();

  const sharePost = () => {
    appDispatch({
      type: 'SET_SHARE_ENTITY_TABBED_MODAL_DATA',
      payload: {
        isOpen: true,
        tabs: [
          'copy_link',
          'share_via_post',
          'share_via_message',
          'share_via_email',
        ],
        entityData: {
          attachment: {
            type: 'post',
            data: feedElement,
          },
        },
      },
    });
  };
  return (
    <Button
      leftSvg={<Share height={22} width={22} />}
      className={classes.shareButton}
      label={t('share')}
      labelClassName={classes.shareButtonLabel}
      schema="ghost"
      labelFont="500"
      onClick={sharePost}
    />
  );
};

export default PostFeedLayoutActions;
