import React from 'react';
import noop from 'lodash/noop';
import IconButton from '@lobox/uikit/Button/IconButton';
import PopperMenu from '@lobox/uikit/PopperMenu';
import PopperItem from '@lobox/uikit/PopperItem';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import {
  useTranslation,
  useReactMutation,
  deleteComment,
  QueryKeys,
  useUpdateInfinityData,
} from '@lobox/utils';
import useFeedElement from '../../../Context/useFeedElement';
import useToast from '@lobox/uikit/Toast/useToast';

export interface CommentMenuProps {
  commentData: any;
  onHideClick: Function;
  onEditClick: Function;
  isMyComment?: boolean;
  isHidden?: boolean;
  refetchCommentList?: Function;
  refetchReplyList?: Function;
  onReportClick: Function;
  setFeedCommentCounter?: (count: number) => void;
  onCopyLink?: Function;
}

const CommentMenu = ({
  commentData,
  onHideClick,
  onEditClick,
  isMyComment,
  isHidden = false,
  onReportClick,
  refetchCommentList,
  refetchReplyList,
  setFeedCommentCounter,
  onCopyLink,
}: CommentMenuProps): JSX.Element => {
  const { mutate: _deleteComment } = useReactMutation({
    apiFunc: deleteComment,
  });
  const { post } = useFeedElement();

  const { openConfirmDialog } = useOpenConfirm();
  const { t } = useTranslation();
  const toast = useToast();

  const commentKey = [QueryKeys.postComments, post.id];
  const repliesKey = [QueryKeys.commentReplies, commentData.replyToId];

  const { remove: removeCommentLocally } = useUpdateInfinityData(commentKey);
  const { remove: removeReplyLocally } = useUpdateInfinityData(repliesKey);

  const handleDeleteComment = () => {
    openConfirmDialog({
      title: 'Delete comment',
      message: 'Are you sure you want to delete this comment?',
      confirmButtonText: 'Delete',
      cancelButtonText: t('confirm_cancel'),
      isAjaxCall: true,
      apiProps: {
        func: _deleteComment,
        variables: { id: commentData.id },
        onSuccess: () => {
          if (commentData.replyTo && refetchReplyList) {
            refetchReplyList();
          }
          if (!commentData.replyTo && refetchCommentList) {
            refetchCommentList();
          }
          setFeedCommentCounter(
            (Number(post.commentCounter) || 0) -
              (1 + (Number(commentData.replyCounter) || 0))
          );
          // event.trigger(eventKeys.deleteComment, {
          //   postId: post.id,
          //   action: 'delete',
          // });
          toast({
            type: 'success',
            icon: 'check-circle',
            message: 'comment_deleted_sucss',
          });

          if (!commentData.replyToId) removeCommentLocally(commentData.id);
          else removeReplyLocally(commentData.id);
        },
        onError: noop,
      },
    });
  };

  const commentActions = [
    {
      icon: 'link',
      label: t('copy_comment_link'),
      onClick: onCopyLink,
    },
  ];

  const myCommentActions = [
    {
      icon: 'pen-light',
      label: t('edit_comment'),
      onClick: onEditClick,
    },
    {
      icon: 'trash',
      label: t('delete_comment'),
      onClick: handleDeleteComment,
    },
  ];
  const othersCommentActions = [
    {
      icon: isHidden ? 'eye' : 'eye-slash',
      label: isHidden ? t('show_comment') : t('hide_comment'),
      onClick: onHideClick,
    },
    {
      icon: 'pennant',
      label: t('report_comment'),
      onClick: () => {
        onReportClick(commentData);
      },
    },
  ];
  const actions = isMyComment
    ? [commentActions, myCommentActions].flat()
    : [commentActions, othersCommentActions].flat();

  return (
    <PopperMenu
      placement="bottom-end"
      buttonComponent={
        <IconButton
          type="far"
          name="ellipsis-h"
          size="sm"
          colorSchema="transparent"
        />
      }
      closeOnScroll
      // disableCloseOnClick
    >
      {actions.map((x) => (
        <PopperItem
          key={x.icon}
          iconName={x.icon}
          label={x.label}
          onClick={x.onClick}
        />
      ))}
    </PopperMenu>
  );
};

export default CommentMenu;
