import React from 'react';
import useBlock from '@shared/hooks/api-hook/useBlock';
import useFeedElement from '../../Context/useFeedElement';
import UnFollowNotify from '../MenuActionBaseUI';

const Block: React.VFC = () => {
  const { getPostOwnerInfo, undoBlock, post } = useFeedElement();
  const userName = getPostOwnerInfo({ userKey: 'name', pageKey: 'title' });
  const { block } = useBlock();

  const handleBlock = () => {
    const id = getPostOwnerInfo({ userKey: 'userId', pageKey: 'id' });
    block({
      entityId: id,
      entityType: post.ownerUserType,
    });
  };
  const handleUndoClicked = () => {
    undoBlock();
  };

  return (
    <UnFollowNotify
      icon="do-not-enter"
      label={`You blocked ${userName}`}
      secondaryLabel="After that, you will not be able to see and communicate with each other"
      undoClicked={handleUndoClicked}
      actionCall={handleBlock}
    />
  );
};

export default Block;
