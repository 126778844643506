import React from 'react';
import Button from '@lobox/uikit/Button';
import CommentIcon from '@shared/svg/CommentActionBottom';
import { useTranslation } from '@lobox/utils';
import useFeedElement from '../../Context/useFeedElement';
import { useFeedDispatch } from '../../Context/feedElement.provider';
import classes from './Action.comment.module.scss';
import useMedia from '@shared/uikit/utils/useMedia';
import Icon from '@shared/uikit/Icon';

const PostFeedLayoutActions: React.FC = () => {
  const { toggleComments } = useFeedElement();
  const { t } = useTranslation();
  const feedDispatch = useFeedDispatch();
  const { isMoreThanTablet } = useMedia();

  const onClickHandler = (e: any) => {
    if (!isMoreThanTablet) e?.stopPropagation?.();
    toggleComments();
    feedDispatch({
      type: 'SET_REPLY_CLICK_COUNTER',
      payload: 1,
    });
  };

  return (
    <Button
      label={t('comment')}
      className={classes.commentButton}
      leftSvg={<Icon name="comment" type="fal" color="primaryText" size={22} />}
      labelClassName={classes.commentButtonLabel}
      schema="ghost"
      labelFont="500"
      onClick={onClickHandler}
    />
  );
};

export default PostFeedLayoutActions;
