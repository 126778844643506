import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Icon from '@lobox/uikit/Icon';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';
import classes from './Comments.empty.module.scss';

const CommentsEmpty: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDir="row" className={classes.container}>
      <Icon name="info-circle" size={22} />
      <Flex className={classes.textContainer}>
        <Typography size={13} height={16}>
          {t('there_i_n_comm_y')}
        </Typography>
        <Typography size={13} height={16}>
          {t('add_y_comm_t_b_f')}
        </Typography>
      </Flex>
    </Flex>
  );
};

export default CommentsEmpty;
