import classes from './Entity.page.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Typography from '@lobox/uikit/Typography';
import Layout, { Avatar } from './EntityView.layout';
import type { BeforeCachePageDetailType } from '@lobox/utils';
import { UnitSectionItemWithData } from '@shared/components/molecules/EntityPopper/UnitSectionItemWithData';

interface SharedPageProps {
  className?: string;
  page: {
    username: string;
  };
}

const SharedPage = ({ page, className }: SharedPageProps): JSX.Element => {
  const { username } = page || {};
  return (
    <UnitSectionItemWithData
      isPage
      hideActions
      username={username}
      className={classes.wrapper}
    />
  );
};

export default SharedPage;
