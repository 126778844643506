import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import classes from './Comment.more.module.scss';
import Button from '@shared/uikit/Button';

interface CommentReplyMoreProps {
  label: string;
  onMoreReplyClick: any;
}

const CommentReplyMore: React.FC<CommentReplyMoreProps> = ({
  label,
  onMoreReplyClick,
}) => {
  return (
    <Flex flexDir="row" className={classes.moreComments}>
      <Divider className={classes.leftDivider} />
      <Button
        rightIcon="chevron-down"
        schema="transparent"
        onClick={onMoreReplyClick}
        className={classes.seeAllButton}
        rightColor="primaryText"
      >
        <Typography
          size={14}
          height={17}
          color="primaryText"
          className={classes.moreCommentsText}
        >
          {label}
        </Typography>
      </Button>
      <Divider className={classes.rightDivider} />
    </Flex>
  );
};

export default CommentReplyMore;
