import useToast from '@lobox/uikit/Toast/useToast';
import { routeNames, useTranslation } from '@lobox/utils';
import copyTextToClipboard from '@shared/uikit/utils/copyToClipboard';

const useCopyCommentLink = () => {
  const { t } = useTranslation();
  const toast = useToast();

  function handleCopyCommentLink(postId: string, commentId: string) {
    const searchPosts = routeNames.searchPosts;
    const query = new URLSearchParams({
      commentId,
      currentEntityId: postId,
    }).toString();
    const link = `${window.location.origin}${searchPosts}?${query}`;
    copyTextToClipboard(link);
    toast({
      type: 'success',
      icon: 'check-circle',
      message: t('t_c_l_h_b_c_t_y_c'),
    });
  }

  return { handleCopyCommentLink };
};

export default useCopyCommentLink;
