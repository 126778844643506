import React, { forwardRef } from 'react';
import Avatar from '@lobox/uikit/Avatar';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import {
  hideComment,
  QueryKeys,
  unHideComment,
  useReactMutation,
  useTranslation,
  useUpdateInfinityData,
} from '@lobox/utils';
import ObjectLink from '@shared/components/molecules/ObjectLink';
import Content from './PureComment.content';
import Menu from './PureComment.menu';
import Actions from './PureComment.actions';
import Reaction from './PureComment.reaction';
import EditComment from '../EditComment';
import useComment from './useComment';
import useFeedElement from '../../../Context/useFeedElement';
import {
  useFeedDispatch,
  useFeedState,
} from '../../../Context/feedElement.provider';
import { EntitiyPopper } from '@shared/components/molecules/EntityPopper/EntitiyPopper';
import classes from './PureComment.component.module.scss';
import useCopyCommentLink from '@shared/utils-pkg/hooks/useCopyCommentLink';

interface PureCommentProps {
  data: any;
  isReply?: boolean;
  refetchCommentList?: Function;
  refetchReplyList?: Function;
  onReplyClick: (item: any) => void;
  setFeedCommentCounter?: (count: number) => void;
}

const PureComment = (
  {
    isReply = false,
    data,
    refetchCommentList,
    refetchReplyList,
    onReplyClick,
    setFeedCommentCounter,
  }: PureCommentProps,
  ref: any
): JSX.Element => {
  const { t } = useTranslation();
  const { toggleReportModal, post } = useFeedElement();
  const { isMoreThanTablet } = useMedia();
  const { mutate: _hideComment } = useReactMutation({
    apiFunc: hideComment,
  });
  const { mutate: _unHideComment } = useReactMutation({
    apiFunc: unHideComment,
  });
  const feedDispatch = useFeedDispatch();
  const commentToEdit = useFeedState('commentToEdit');
  const [isHidden, setIsHidden] = React.useState(data?.hideIt || false);

  const openEditMode = () => {
    feedDispatch({
      type: 'TOGGLE_COMMENT_TO_EDIT',
      payload: { comment: { isReply, ...data } },
    });
  };

  const { replace: replaceComment } = useUpdateInfinityData([
    QueryKeys.postComments,
    post.id,
  ]);
  const { replace: replaceReplyComment } = useUpdateInfinityData([
    QueryKeys.commentReplies,
    data?.replyToId,
  ]);
  const {
    getCommentOwnerInfo,
    isMyComment,
    reactionsCount,
    reactions,
    MyReaction,
    isBusinessComment,
  } = useComment(data);

  const handleReplyClicked = () => {
    onReplyClick(data);
  };

  const handleHideClicked = () => {
    if (!isHidden) {
      setIsHidden(() => true);
      _hideComment({ commentId: data.id });
    } else {
      setIsHidden(() => false);
      _unHideComment({ commentId: data.id });
    }
  };

  const handleReportClicked = () => {
    toggleReportModal({
      entityType: 'comment',
      entityId: data.id,
    });
  };

  const objectId = getCommentOwnerInfo({
    userKey: 'userId',
    pageKey: 'id',
  });

  const ownerName = getCommentOwnerInfo({
    userKey: 'fullName',
    pageKey: 'title',
  });

  const handleAddReaction = (reaction: string, res: any) => {
    const _comment = {
      ...data,
      ...(res || {}),
      myAction: reaction,
    };
    if (!isReply) replaceComment(_comment);
    else replaceReplyComment(_comment);
  };

  const handleRemoveReaction = (res: any) => {
    const _comment = {
      ...data,
      ...(res || {}),
      myAction: undefined,
    };
    if (!isReply) replaceComment(_comment);
    else replaceReplyComment(_comment);
  };

  const closeEditMode = () => {
    feedDispatch({
      type: 'TOGGLE_COMMENT_TO_EDIT',
      payload: { comment: undefined },
    });
  };

  const { handleCopyCommentLink } = useCopyCommentLink();

  const handleCopyLink = () => {
    handleCopyCommentLink(post.id, data?.id);
  };

  return (
    <Flex className={classes.containerWrapper}>
      <Flex
        flexDir="row"
        className={cnj(classes.container)}
        ref={ref}
        data-id={data?.id}
      >
        {commentToEdit &&
        commentToEdit.isReply &&
        isMoreThanTablet &&
        data?.id === commentToEdit?.id ? (
          <EditComment
            avatarSize={commentToEdit?.isReply ? 'xxs' : 'smd'}
            inputAlignment="start"
            selectedComment={data}
            className={cnj(
              classes.editComment,
              commentToEdit?.isReply && classes.reply
            )}
            onEditSuccess={closeEditMode}
            inputWrapperClassName={classes.inputWrapperClassName}
          />
        ) : (
          <>
            <ObjectLink objectId={objectId}>
              <EntitiyPopper
                username={objectId}
                useId
                isPage={isBusinessComment}
              >
                <Avatar
                  isCompany={isBusinessComment}
                  size={isReply ? 'xxs' : 'smd'}
                  className={cnj({ [classes.hiddenOpacity]: isHidden })}
                  imgSrc={getCommentOwnerInfo({
                    userKey: 'croppedImageUrl',
                    pageKey: 'croppedImageUrl',
                  })}
                />
              </EntitiyPopper>
            </ObjectLink>
            <Flex className={classes.content}>
              {isHidden ? (
                <Typography
                  size={16}
                  height={20}
                  font="bold"
                  color="primaryText"
                  className={cnj({
                    [classes.hiddenOpacity]: isHidden,
                  })}
                >
                  {t('comment_is_hidden')}
                </Typography>
              ) : (
                <>
                  <Content
                    href={objectId}
                    userName={ownerName}
                    edited={data?.edited}
                    body={data?.body}
                    renderMenu={() => (
                      <Flex className={classes.menu}>
                        <Menu
                          isMyComment={isMyComment}
                          isHidden={isHidden}
                          commentData={data}
                          refetchCommentList={refetchCommentList}
                          refetchReplyList={refetchReplyList}
                          onHideClick={handleHideClicked}
                          onEditClick={openEditMode}
                          onReportClick={handleReportClicked}
                          setFeedCommentCounter={setFeedCommentCounter}
                          onCopyLink={handleCopyLink}
                        />
                      </Flex>
                    )}
                  />
                  <Flex flexDir="row">
                    <Actions
                      comment={data}
                      onReplyClick={handleReplyClicked}
                      createdDate={data?.createdDate}
                      onAddReaction={handleAddReaction}
                      onRemoveReaction={handleRemoveReaction}
                    />
                    {reactionsCount > 0 ? (
                      <Reaction
                        MyReaction={MyReaction}
                        count={reactionsCount}
                        data={reactions}
                        comment={data}
                      />
                    ) : null}
                  </Flex>
                </>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default forwardRef<any, any>(PureComment);
