import React, { createContext, useRef, useState } from 'react';
import type * as MODAL_KEYS from '../constants/profileModalsKeys';
import { CREATE_PAGE_FORM, EDIT_PROFILE } from '../constants/profileModalsKeys';

export type ModalStateType = {
  active?: keyof typeof MODAL_KEYS;
  selectedForEdit?: keyof typeof MODAL_KEYS;
  prev: Array<keyof typeof MODAL_KEYS>;
  params?: { [key: string]: any };
};

export const ProfileModalsContext = createContext<{
  modals: ModalStateType;
  hasPrev: () => any;
  open: (modalKey: keyof typeof MODAL_KEYS, params: any) => void;
  goBack: (params: any) => void;
  close: () => any;
  setSelectedForEdit: (modalKey: keyof typeof MODAL_KEYS) => void;
  // @ts-ignore
}>(undefined);

const INIT_STATE = {
  active: undefined,
  selectedForEdit: undefined,
  prev: [],
  params: {},
};

const useProfileModals = () => {
  const [modals, setModals] = useState<ModalStateType>(INIT_STATE);
  const modalKeyRef = useRef(null);

  const enableBackButton = () => {
    if ([CREATE_PAGE_FORM, EDIT_PROFILE].includes(modalKeyRef.current || '')) {
      window.history.back();
    }
  };

  const open = (modalKey: keyof typeof MODAL_KEYS, params: any) => {
    // @ts-ignore
    modalKeyRef.current = modalKey;
    setModals((prevState: ModalStateType) => ({
      ...prevState,
      active: modalKey,
      prev: [
        ...prevState.prev,
        ...(prevState.active
          ? prevState.prev?.includes(prevState.active)
            ? []
            : [prevState.active]
          : []),
        modalKey,
      ],
      params: { [modalKey]: params },
    }));
  };

  const goBack = (params: any) => {
    setModals((prevState: ModalStateType) => {
      if (prevState?.prev?.length === 0) {
        return { ...INIT_STATE, selectedForEdit: prevState.selectedForEdit };
      }
      const prevModals = prevState?.prev.filter((i) => i !== prevState?.active);
      const [last, ...rest] = prevModals.reverse();
      if (!last) {
        return { ...INIT_STATE, selectedForEdit: prevState.selectedForEdit };
      }
      return {
        ...prevState,
        active: last,
        prev: rest,
        params: { [last]: params },
      };
    });
  };

  const close = () => {
    enableBackButton();
    setModals((prevState) => ({
      ...INIT_STATE,
      selectedForEdit: prevState.selectedForEdit,
    }));
  };

  const setSelectedForEdit = (modalKey: keyof typeof MODAL_KEYS) => {
    setModals((prevState: ModalStateType) => ({
      ...prevState,
      selectedForEdit: modalKey,
    }));
  };

  const hasPrev = () =>
    modals?.prev?.filter((i) => i !== modals?.active)?.length > 0;

  return {
    modals,
    hasPrev,
    open,
    goBack,
    close,
    setSelectedForEdit,
  };
};

interface ProfileModalsProviderProps {
  children?: ReactNode;
}

const ProfileModalsProvider: React.FC<ProfileModalsProviderProps> = ({
  children,
}) => {
  const modalProps = useProfileModals();

  return (
    <ProfileModalsContext.Provider value={modalProps}>
      {children}
    </ProfileModalsContext.Provider>
  );
};

export default ProfileModalsProvider;
