import React from 'react';
import Flex from '@lobox/uikit/Flex';
import dynamic from 'next/dynamic';
import Attachment from '@shared/components/Organism/CreatePost/components/Attachment/Attachment.component';
import { useGetPostAttachmentData } from '@shared/hooks/useGetPostAttachmentData';
import useSearchFilters from '@shared/hooks/useSearchFilters';
import SearchPostsItemBody from '@shared/components/Organism/SearchPosts/SearchPosts.itemBody';
import useFeedElement from '../../Context/useFeedElement';
import classes from './FeedBody.component.module.scss';
import cnj from '@lobox/uikit/utils/cnj';
import FeedCard from '@shared/components/Organism/FeedCard';

const Description = dynamic(() => import('../../Components/Description'));
const PagePreview = dynamic(() => import('../../Components/PagePreview'));
const HighlightPreview = dynamic(
  () => import('../../Components/HighlightPreview')
);
const LinkPreview = dynamic(() => import('../../Components/LinkPreview'));
const Media = dynamic(() => import('../../Components/FeedMedia'));
const Location = dynamic(() => import('../../Components/Location'));

const PostBody: React.FC = () => {
  const {
    post,
    isHighlight,
    isSharedHighlight,
    isPage,
    location,
    medias,
    hasBodyDescription,
    variant,
    linksListInBody,
    highlightData,
    postWithoutAnyReactions,
    isSharedPost,
  } = useFeedElement();
  const { searchFiltersBackend } = useSearchFilters();
  const jobId = Number(post?.sharedJobId || post?.sharedOf?.sharedJobId);
  // It should show preview of the shared job in 2 places
  // - When someone shares a job directly
  // - When someone shares a shared job

  const jobData = useGetPostAttachmentData(jobId, 'job');

  const hasJob = Boolean(jobId);

  if (variant === 'search-view') {
    return (
      <Flex className={classes.searchViewBodyWrapper}>
        <SearchPostsItemBody />
        {isPage && <PagePreview />}
        {hasJob && (
          <Attachment
            entityData={jobData}
            entityType="job"
            footerProps={{ classes: classes.footer }}
            className={classes.jobContainer}
          />
        )}
      </Flex>
    );
  }

  return (
    <Flex>
      {hasBodyDescription && (
        <Description
          isPlainText={
            !linksListInBody?.length &&
            !medias &&
            !isHighlight &&
            !isSharedHighlight &&
            !isPage &&
            !location &&
            !isPage &&
            !hasJob &&
            !isSharedPost &&
            variant !== 'attachment-view'
          }
          postWithoutAnyReactions={postWithoutAnyReactions}
        />
      )}
      {isSharedPost && (
        <FeedCard
          feedElement={{ post: post.sharedOf, id: post.id }}
          variant="attachment-view"
          className={classes.sharedPostWrapper}
        />
      )}
      {isHighlight && (
        <HighlightPreview
          className={cnj(!hasBodyDescription && classes.highlightWrapper)}
          showDivider={!!medias || variant === 'attachment-view'}
        />
      )}
      {medias ? (
        <Media
          mediaList={medias}
          playByDefault={searchFiltersBackend.currentEntityId === post.id}
        />
      ) : location &&
        !isPage &&
        !isHighlight &&
        !isSharedHighlight &&
        !isSharedPost &&
        (post.location?.preview || post.sharedOf?.location?.preview) ? (
        <Location />
      ) : linksListInBody?.length ? (
        <LinkPreview
          body={post.body || highlightData?.description}
          url={linksListInBody[0]}
        />
      ) : null}
      {isPage && (
        <Attachment
          entityType="page"
          entityData={post.sharedPage}
          classNames={{
            attachmentWrapper: cnj(classes.noEvents, classes.sharedPostWrapper),
          }}
        />
      )}
      {hasJob && (
        <Attachment
          entityData={jobData}
          entityType="job"
          footerProps={{ classes: classes.footer }}
          className={classes.jobContainer}
        />
      )}
    </Flex>
  );
};
export default PostBody;
