import classes from './JobCover.component.module.scss';

import React, { Fragment } from 'react';
import Avatar from '@lobox/uikit/Avatar';
import cnj from '@lobox/uikit/utils/cnj';
import DividerVertical from '@lobox/uikit/Divider/DividerVertical';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Media from '@lobox/uikit/Media';
import OverflowTip from '@lobox/uikit/Typography/OverflowTip';
import Typography from '@lobox/uikit/Typography';
import type { TextProps } from '@lobox/uikit/Typography';

interface JobCoverProps {
  data: {
    firstText?: string | ReactNode;
    secondText?: string | ReactNode;
    thirdText?: string | ReactNode;
    image?: any;
    objectId?: string;
    jobId?: string;
  };
  action?: React.ReactNode;
  bottomActions?: React.ReactNode;
  hintActions?: React.ReactNode;
  jobInfo?: Array<{ title: string; value: any }>;
  className?: string;
  backButtonProps?: { className?: string; onClick?: () => void };
  firstTextProps?: TextProps;
  thirdTextProps?: TextProps;
  isMessageVariant?: boolean;
}

const JobCover = ({
  data,
  action,
  jobInfo,
  className,
  bottomActions,
  backButtonProps,
  hintActions,
  firstTextProps = {},
  thirdTextProps = {},
  isMessageVariant,
}: JobCoverProps): JSX.Element => {
  const { firstText, secondText, thirdText, image } = data;

  const JobTitles = ({ className: clName }: any) => (
    <Flex className={clName}>
      <OverflowTip
        font="700"
        color="smoke_coal"
        {...firstTextProps}
        className={classes.jobTitle}
        isTruncated
      >
        {firstText}
      </OverflowTip>
      <OverflowTip
        height={15}
        size={15}
        color="secondaryDisabledText"
        className={classes.subtitle}
        isTruncated
      >
        {secondText}
      </OverflowTip>
      <OverflowTip
        height={14}
        size={14}
        {...thirdTextProps}
        className={classes.belowSubtitle}
        isTruncated
      >
        {thirdText}
      </OverflowTip>
    </Flex>
  );

  const JobType = () => (
    <Flex flexDir="row">
      {jobInfo.map(({ title, value }) => (
        <Fragment key={title}>
          <Flex>
            <Flex className={classes.jobType}>
              <Typography color="colorIconForth2" size={12} height={18} mb={6}>
                {title}
              </Typography>
              <Typography
                font="700"
                className={classes.jobInfoValue}
                color="thirdText"
                height={20}
                size={16}
              >
                {value}
              </Typography>
            </Flex>
          </Flex>
          <DividerVertical color="techGray_10" className={classes.divider} />
        </Fragment>
      ))}
    </Flex>
  );

  return (
    <Flex className={cnj(classes.jobCoverRoot, className)}>
      <Flex
        className={cnj(
          classes.jobCoverContainer,
          isMessageVariant && classes.jobCoverContainerMessageVariant
        )}
      >
        <Media lessThan={isMessageVariant || 'midDesktop'}>
          <Flex>
            <Flex flexDir="row">
              <Avatar
                imgSrc={image}
                className={cnj(!isMessageVariant && classes.avatar)}
                isCompany
                size="md"
              />
              <JobType />
              <Flex flexDir="row" className={classes.actionButtonWrapper}>
                {action}
              </Flex>
            </Flex>
            <JobTitles className={classes.jobTitleWrapper} />
            {!!bottomActions && (
              <Flex className={classes.bottomActionsWrapper}>
                {bottomActions}
              </Flex>
            )}
          </Flex>
        </Media>
        {!isMessageVariant && (
          <Media greaterThan="tablet">
            <Flex flexDir="row">
              {backButtonProps && (
                <IconButton
                  name="chevron-left"
                  type="far"
                  {...backButtonProps}
                  className={cnj(classes.backBtn, backButtonProps?.className)}
                />
              )}
              <Avatar
                // onClick={!disabled ? onClickAvatarHandler : undefined}
                imgSrc={image}
                className={classes.avatar}
                isCompany
                size="vlg"
              />
              <Flex className={classes.jobInfoWrapper}>
                <Flex className={classes.topWrapper}>
                  <JobTitles className={classes.jobTitleWrapper} />
                  <Flex flexDir="row" className={classes.actionButtonWrapper}>
                    {action}
                  </Flex>
                </Flex>
                <Flex className={classes.bottomWrapper}>
                  <JobType />
                  <Flex className={classes.rightActionWrapper}>
                    {bottomActions}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex className={classes.bottomActionsWrapper}>
              {bottomActions}
            </Flex>
          </Media>
        )}
        {hintActions}
      </Flex>
    </Flex>
  );
};

export default JobCover;
