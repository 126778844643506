import React from 'react';

import { APP_ENTITIES, PostReactions } from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import sum from 'lodash/sum';
import Like from '@shared/components/molecules/Reactions/Like';
import Boost from '@shared/components/molecules/Reactions/Boost';
import Celebrate from '@shared/components/molecules/Reactions/Celebrate';
import NotRelevant from '@shared/components/molecules/Reactions/NotRelevant';
import DisLike from '@shared/components/molecules/Reactions/DisLike';

interface UseCommentReturnProps {
  isUserComment: boolean;
  isBusinessComment: boolean;
  getCommentOwnerInfo: (props: { userKey: string; pageKey: string }) => any;
  isMyComment: boolean;
  reactionsCount: number;
  reactions: any[];
  MyReaction: React.ReactNode | undefined;
}

const useComment = (comment: any): UseCommentReturnProps => {
  const { isBusinessApp, authUser, businessPage } = useGetAppObject();
  const {
    ownerUserType,
    ownerId,
    ownerProfileInfo,
    ownerPageInfo,
    boostActionCounter,
    celebrateActionCounter,
    dislikeActionCounter,
    likeActionCounter,
    notRelevantActionCounter,
    myAction,
  } = comment;

  if (ownerProfileInfo) {
    comment.ownerProfileInfo.fullName = `${comment.ownerProfileInfo?.name} ${comment.ownerProfileInfo?.surname}`;
  }
  const isUserComment = ownerUserType === APP_ENTITIES.person;
  const isBusinessComment = ownerUserType === APP_ENTITIES.page;

  const makeObjectInfo =
    () =>
    ({ userKey, pageKey }: { userKey: string; pageKey: string }) =>
      isUserComment ? ownerProfileInfo?.[userKey] : ownerPageInfo?.[pageKey];

  const getCommentOwnerInfo = makeObjectInfo();

  const isMyComment = isBusinessApp
    ? ownerId === businessPage?.id
    : ownerId === authUser?.id;

  const reactions = [
    {
      key: 'like',
      value: likeActionCounter || 0,
      Render: Like,
    },
    {
      key: 'boost',
      value: boostActionCounter || 0,
      Render: Boost,
    },
    {
      key: 'celebrate',
      value: celebrateActionCounter || 0,
      Render: Celebrate,
    },
    {
      key: 'disLike',
      value: dislikeActionCounter || 0,
      Render: DisLike,
    },
    {
      key: 'notRelevant',
      value: notRelevantActionCounter || 0,
      Render: NotRelevant,
    },
  ].sort((a, b) => (b.value || 0) - (a.value || 0));
  const reactionsCount = sum([
    parseInt(reactions[0].value, 10),
    parseInt(reactions[1].value, 10),
    parseInt(reactions[2].value, 10),
    parseInt(reactions[3].value, 10),
    parseInt(reactions[4].value, 10),
  ]);
  const MyReaction =
    myAction === PostReactions.like
      ? Like
      : myAction === PostReactions.boost
      ? Boost
      : myAction === PostReactions.celebrate
      ? Celebrate
      : myAction === PostReactions.disLike
      ? DisLike
      : myAction === PostReactions.notRelevant
      ? NotRelevant
      : undefined;

  return {
    MyReaction,
    reactions,
    // reactionsCount: MyReaction ? reactionsCount + 1 : reactionsCount,
    reactionsCount,
    isUserComment,
    isBusinessComment,
    getCommentOwnerInfo,
    isMyComment,
  };
};

export default useComment;

// body: "<p>hi amer this is my first comment for you</p>"
// boostActionCounter: null
// celebrateActionCounter: null
// dislikeActionCounter: null
// edited: false
// hideIt: false
// id: "1"
// likeActionCounter: null
// notRelevantActionCounter: null
// ownerId: "1425639914"
// ownerPageInfo: null
// ownerProfileInfo: {userId: "1425639914", username: "saeed", name: "Saeed", surname: "Padyab",…}
// ownerUserType: "PERSON"
// postId: "48"
// replyCounter: null
// replyTo: null
