import React, { forwardRef } from 'react';
import { useTranslation } from '@lobox/utils';
import Button from '@lobox/uikit/Button';
import DateView from '@lobox/uikit/DateView';
import Flex from '@lobox/uikit/Flex';
import useTheme from '@lobox/uikit/utils/useTheme';
import cnj from '@lobox/uikit/utils/cnj';
import { FollowingButton } from '@shared/components/molecules';
import useObjectNetwork from '@shared/hooks/api-hook/useObjectNetwork';
import { DefaultTagLinkComponent } from '@shared/components/molecules/TranslateReplacer';
import { EntitiyPopper } from '@shared/components/molecules/EntityPopper/EntitiyPopper';
import PostMediaBadge from '@shared/components/Organism/PostMediaBadge';
import Menu from '../../Components/PostMenuItems';
import HeaderAvatar from '../../Components/HeaderAvatar';
import useFeedElement from '../../Context/useFeedElement';
import PostHeaderText from '../../Components/PostHeaderText';
import classes from './header.component.module.scss';

const PostFeedLayoutHeader = ({}, ref: any): JSX.Element => {
  const { isDark } = useTheme();
  const { t } = useTranslation();
  const { followHandler, isLoading } = useObjectNetwork();

  const {
    visibleNetworkInfoOnHeader,
    getPostOwnerInfo,
    isBusinessPost,
    post,
    follow,
    isMyPost,
    followPostOwner,
    undoFollowPostOwner,
    variant,
    myFollowingReactionOnPost,
    isSharedPost,
    isSharedPage,
    isSharedHighlight,
    feedMediaType,
  } = useFeedElement();
  const isSharedEntity = isSharedPost || isSharedPage || isSharedHighlight;

  const objectId = getPostOwnerInfo({
    userKey: 'userId',
    pageKey: 'id',
  });
  const avatar = getPostOwnerInfo({
    userKey: 'croppedImageUrl',
    pageKey: 'croppedImageUrl',
  });
  const postOwnerTitle = getPostOwnerInfo({
    userKey: 'fullName',
    pageKey: 'title',
  });
  const id = React.useMemo(
    () =>
      getPostOwnerInfo({
        userKey: 'userId',
        pageKey: 'id',
      }),
    [] // eslint-disable-line
  );
  const handleFollow = () => {
    followHandler(
      { id, isPage: isBusinessPost },
      {
        onSuccess: () => {
          followPostOwner();
        },
      }
    );
  };
  const handleUnFollowSuccess = () => {
    undoFollowPostOwner();
  };

  return (
    <Flex
      flexDir="row"
      className={cnj(
        classes.feedHeaderContainer,
        variant === 'search-view' && classes.feedHeaderSearchView
      )}
      ref={ref}
    >
      <EntitiyPopper username={objectId} useId isPage={isBusinessPost}>
        <HeaderAvatar
          objectId={objectId}
          isBusiness={isBusinessPost}
          src={avatar}
          size={variant === 'search-view' ? 'xxs' : 'smd'}
        />
      </EntitiyPopper>
      <Flex className={classes.textContainer}>
        <Flex flexDir="row" className={classes.textContent}>
          {variant === 'message' ? (
            <DefaultTagLinkComponent
              text={postOwnerTitle}
              objectId={objectId}
            />
          ) : (
            <PostHeaderText
              height={18}
              objectId={objectId}
              postOwnerTitle={postOwnerTitle}
              tags={post?.tags}
              isSharedEntity={isSharedEntity}
              locationTitle={post?.location?.title}
              sharedEntityTitle={t(
                isSharedHighlight
                  ? 'highlight_sm'
                  : isSharedPage
                    ? 'page_sm'
                    : 'post_sm'
              )}
            />
          )}
        </Flex>

        <DateView
          value={post?.createdDate}
          font="400"
          height={14}
          size={12}
          color="secondaryDisabledText"
        />
      </Flex>
      {variant === 'search-view' ? (
        <PostMediaBadge
          className={classes.postMediaBadge}
          feedMediaType={feedMediaType}
        />
      ) : variant !== 'message' ? (
        variant === 'light-box' ||
        variant === 'attachment-view' ||
        (visibleNetworkInfoOnHeader && myFollowingReactionOnPost && follow) ? (
          post.localFollow ? (
            <FollowingButton
              className={classes.followBtn}
              object={{
                isPage: isBusinessPost,
                id,
                username: null,
                name: null,
                needsAlert: false,
              }}
              onSuccess={handleUnFollowSuccess}
            />
          ) : null
        ) : visibleNetworkInfoOnHeader &&
          myFollowingReactionOnPost &&
          !follow &&
          !isMyPost ? (
          <Button
            className={classes.followBtn}
            isLoading={isLoading}
            schema={isDark ? 'secondary-dark' : 'semi-transparent'}
            leftIcon="plus"
            leftType="fas"
            label={t('follow_cap')}
            onClick={handleFollow}
          />
        ) : (
          <Menu />
        )
      ) : null}
    </Flex>
  );
};

export default forwardRef<any, any>(PostFeedLayoutHeader);
