import React from 'react';
import Avatar from '@lobox/uikit/Avatar';
import ObjectLink from '@shared/components/molecules/ObjectLink';
import classes from './HaderAvatar.component.module.scss';

export interface Props {
  src: string;
  objectId: string;
  isBusiness?: boolean;
  size?: 'sm' | 'smd';
}

const HeaderAvatar = ({
  size = 'sm',
  src,
  objectId,
  isBusiness,
}: Props): JSX.Element => {
  return (
    <ObjectLink objectId={objectId}>
      <Avatar
        size={size}
        isCompany={isBusiness}
        imgSrc={src}
        className={classes.avatar}
      />
    </ObjectLink>
  );
};

export default HeaderAvatar;
