import React, { useEffect, useRef } from 'react';
import Box from '@lobox/uikit/Layout/Box';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import useMedia from '@lobox/uikit/utils/useMedia';
import dynamic from 'next/dynamic';
import PostThumbnailMedia from '@shared/components/Organism/PostThumbnailMedia/PostThumbnailMedia.component';
import postTypes from '@shared/constants/postTypes';
import MyFollowingReactionHeader from './MyFollowingReactionHeader';
import Header from './Header';
import Body from './Body';
import Actions from './Actions';
import Reactions from './Reactions';
import UnFollow from '../Components/UnFollow';
import Block from '../Components/Block';
import Divider from '../Components/Divider';
import TempHidden from '../Components/TempHidden';
import Comments from '../Components/Comments';
import useFeedElement from '../Context/useFeedElement';
import { useUrlQuery } from '@lobox/utils';
import classes from './Layout.component.module.scss';

const MobileComments = dynamic(() => import('../modals/MobileComments'));

interface Props {
  showInitialComments?: boolean;
  className?: string;
}

const PostFeedLayoutComponent: React.FC<Props> = ({
  className,
  showInitialComments,
}) => {
  const { isTabletAndLess, isMoreThanTablet } = useMedia();
  const layoutRef = useRef(null);
  const headerRef = useRef(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const reactionsRef = useRef<HTMLElement>();
  const videoRef = useRef<HTMLVideoElement>();
  const query = useUrlQuery();
  const commentId = query.get('commentId');

  const {
    isMyFollowingsReactionOnPost,
    isTempHidden,
    unFollowed,
    isBlocked,
    isOpenComments,
    visibleNetworkInfoOnHeader,
    variant,
    toggleComments,
    setCommentCounter,
    feedMediaType,
  } = useFeedElement();

  const isSearchVariant = variant === 'search-view';

  useEffect(() => {
    if ((showInitialComments && isMoreThanTablet) || !!commentId)
      toggleComments(true);
  }, [showInitialComments, isMoreThanTablet]);

  const setVideoRef = (ref: React.MutableRefObject<HTMLVideoElement>) => {
    videoRef.current = ref?.current;
    videoRef.current?.pause();
  };

  if (isTempHidden) return <TempHidden />;
  if (unFollowed) return <UnFollow />;
  if (isBlocked) return <Block />;

  return (
    <Box
      onMouseEnter={
        isSearchVariant ? () => videoRef.current?.play() : undefined
      }
      onMouseLeave={
        isSearchVariant ? () => videoRef.current?.pause() : undefined
      }
      padding={0}
      ref={layoutRef}
      className={cnj(
        classes.layoutContainer,
        variant === 'attachment-view' && classes.attachmentView,
        variant === 'message' && classes.messageView,
        isSearchVariant && classes.searchView,
        className
      )}
    >
      {isSearchVariant && feedMediaType !== postTypes.TEXT && (
        <PostThumbnailMedia
          size="medium"
          className={classes.thumbnailWrapper}
          setVideoRef={setVideoRef}
        />
      )}
      <Flex
        ref={contentRef}
        className={cnj(isSearchVariant && classes.searchViewRightSide)}
      >
        {variant !== 'attachment-view' &&
          variant !== 'message' &&
          isMyFollowingsReactionOnPost &&
          visibleNetworkInfoOnHeader && <MyFollowingReactionHeader />}
        <Header ref={headerRef} />
        <Body />
        {variant !== 'attachment-view' && variant !== 'message' ? (
          <Flex className={cnj(isSearchVariant && classes.searchViewFooter)}>
            <Reactions visibleDivider ref={reactionsRef} />
            <Actions />
            {isOpenComments && !isTabletAndLess && (
              <>
                <Divider />
                <Comments
                  setFeedCommentCounter={setCommentCounter}
                  reactionsRef={reactionsRef}
                />
              </>
            )}
            {isOpenComments && isTabletAndLess && <MobileComments />}
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
};

export default PostFeedLayoutComponent;
