import React from 'react';
import Box from '@lobox/uikit/Layout/Box';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import ReactionInfo from '../../../layout/Reactions/ReactionInfo.component';
import dynamic from 'next/dynamic';
import { useDisclosure, useTranslation } from '@lobox/utils';
import type { Comment as IComment } from '@lobox/utils';
import classes from './PureComment.reaction.module.scss';

const ReactionsModal = dynamic(() => import('../../../modals/PostReactions'));

interface PureCommentReactionsProps {
  MyReaction: React.ReactNode;
  count: number;
  data: any[];
  comment: IComment;
}

const PureCommentReactionsProps: React.FC<PureCommentReactionsProps> = ({
  count,
  data,
  comment,
}) => {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Box
        radius={4}
        padding={4}
        flexDir="row"
        className={classes.commentRacActions}
        onClick={onToggle}
      >
        {data.map((x, i) => {
          const reactionType: string = {
            like: 'LIKE',
            boost: 'BOOST',
            celebrate: 'CELEBRATE',
            disLike: 'DISLIKE',
            notRelevant: 'NOT_RELEVANT',
          }[x.key as string];

          if (x?.value > 0) {
            return (
              <ReactionInfo
                key={x?.key}
                type={reactionType}
                commentId={comment?.id}
                title={t(reactionType?.toLowerCase())}
              >
                <Flex style={{ zIndex: -i }}>
                  <x.Render
                    className={classes.reactionIcons}
                    hasHover={false}
                    size="small"
                  />
                </Flex>
              </ReactionInfo>
            );
          }

          return null;
        })}

        <ReactionInfo commentId={comment.id}>
          <div className={classes.countReaction}>
            <Typography font="400" height={18} color="border" size={14}>
              {count}
            </Typography>
          </div>
        </ReactionInfo>
      </Box>
      {isOpen && (
        <ReactionsModal
          onClose={onToggle}
          comment={comment}
          reactions={data}
          allReactionsCount={count}
        />
      )}
    </>
  );
};

export default PureCommentReactionsProps;
