export const PRO_EXPERIENCE = 'PRO_EXPERIENCE';
export const VOL_EXPERIENCE = 'VOL_EXPERIENCE';
export const BIO = 'BIO';
export const EDUCATION = 'EDUCATION';
export const COURSE = 'COURSE';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const SCHOOL = 'SCHOOL';
export const LICENCE = 'LICENCE';
export const ACCOMPLISHMENT = 'ACCOMPLISHMENT';
export const PUBLICATION = 'PUBLICATION';
export const HONOR = 'HONOR';
export const PATENT = 'PATENT';
export const ADD_SECTION = 'ADD_SECTION';
export const ASK_RECOMMENDATION = 'ASK_RECOMMENDATION';
export const SKILL_UPSERT = 'SKILL_UPSERT';
export const RESUME = 'RESUME';
export const LANGUAGE_UPSERT = 'LANGUAGE_UPSERT';
export const DATE_OF_BIRTH = 'DATE_OF_BIRTH';
export const PHONE_NUMBER = 'PHONE_NUMBER';
export const LOCATION = 'LOCATION';
export const WEB_SITE = 'WEB_SITE';
export const FOLLOW = 'FOLLOW';
export const FOLLOWING = 'FOLLOWING';
export const REQUEST = 'REQUEST';
export const FOLLOWERS = 'FOLLOWERS';
export const ASK_REVISION = 'ASK_REVISION';
export const RECEIVED_RECOMMENDATION = 'RECEIVED_RECOMMENDATION';
export const GIVEN_RECOMMENDATION = 'GIVEN_RECOMMENDATION';
export const REQUEST_RECOMMENDATION = 'REQUEST_RECOMMENDATION';
export const SHARE_RESUME_VIA_MESSAGE = 'SHARE_RESUME_VIA_MESSAGE';
export const CREATE_PAGE_FORM = 'CREATE_PAGE_FORM';
