import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import Flex from '@lobox/uikit/Flex';
import Spinner from '@lobox/uikit/Spinner';
import Typography from '@lobox/uikit/Typography';
import useGetPostComments from '@shared/hooks/api-hook/useGetPostComments';
import { useTranslation } from '@lobox/utils';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import Comment from './Comment';
import useFeedElement from '../../Context/useFeedElement';
import EmptyList from './Comments.empty';
import classes from './Comments.list.module.scss';
import {
  useFeedDispatch,
  useFeedState,
} from '../../Context/feedElement.provider';
import Button from '@shared/uikit/Button';
import { Divider } from '@material-ui/core';

export interface Props {
  setFeedCommentCounter?: (count: number) => void;
  onClickOnReply?: Function;
}
export interface RepliesData {
  totalCount: number;
  countByIndex: number[];
}

const refetchPlaceholder = (): null => null;
const totalInitialCommentCount = 12;

const CommentsList = ({
  setFeedCommentCounter,
  onClickOnReply,
}: Props): JSX.Element => {
  const [initReplySizes, setInitReplySizes] = React.useState<
    RepliesData['countByIndex']
  >([]);

  const { post } = useFeedElement();
  const { t } = useTranslation();
  const {
    data = [],
    refetch,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
  }: any = useGetPostComments({
    postId: post.id,
    size: 6,
  });

  React.useEffect(() => {
    if (initReplySizes.length) {
      return;
    }

    const commentCount = data.length;
    const replyCountData = reduce(
      data,
      (acc: RepliesData, { replyCounter }) => {
        const counter = Number(replyCounter) || 0;
        acc.countByIndex.push(counter);
        acc.totalCount += counter;
        return acc;
      },
      {
        totalCount: 0,
        countByIndex: [],
      }
    );

    let repliesCountToShow = totalInitialCommentCount - commentCount;

    if (replyCountData.totalCount <= repliesCountToShow) {
      setInitReplySizes((replyCount) =>
        isEqual(replyCount, replyCountData.countByIndex)
          ? replyCount
          : replyCountData.countByIndex
      );
      return;
    }

    const finalReplyCount = Array.from(replyCountData.countByIndex, () => 0);

    while (repliesCountToShow) {
      for (let ii = 0; ii < replyCountData.countByIndex.length; ii += 1) {
        const count = replyCountData.countByIndex[ii];
        if (
          count > 0 &&
          finalReplyCount[ii] < count &&
          repliesCountToShow > 0
        ) {
          finalReplyCount[ii] += 1;
          repliesCountToShow -= 1;
        }
      }
    }

    setInitReplySizes((replyCount) =>
      isEqual(replyCount, finalReplyCount) ? replyCount : finalReplyCount
    );
  }, [initReplySizes, data]);

  const refetchComments = hasNextPage ? refetch : refetchPlaceholder;
  const loading =
    isLoading ||
    isFetching ||
    isFetchingNextPage ||
    (Boolean(data.length) && !initReplySizes.length);

  const handleMoreClicked = () => {
    fetchNextPage();
  };

  const comments = React.useMemo(
    () =>
      reduce(
        data,
        (acc, comment) => {
          if (comment.recentlyAdded) {
            acc.recentlyAdded.unshift(comment);
            acc.recentlyAddedIds[comment.id] = comment.id;
          }
          if (!comment.recentlyAdded && !acc.recentlyAddedIds[comment.id]) {
            acc.previouslyAdded.push(comment);
          }
          return acc;
        },
        {
          previouslyAdded: [],
          recentlyAdded: [],
          recentlyAddedIds: {} as any,
        }
      ),
    [data]
  );

  return (
    <Flex>
      {!loading &&
        !comments.previouslyAdded.length &&
        !comments.recentlyAdded.length && <EmptyList />}
      {comments.recentlyAdded.map((comment: any) => (
        <Comment
          key={comment.id}
          data={comment}
          refetch={refetchComments}
          setFeedCommentCounter={setFeedCommentCounter}
          onClickOnReply={onClickOnReply}
        />
      ))}
      {comments.previouslyAdded.map((comment: any, ii: number) => (
        <Comment
          key={comment.id}
          data={comment}
          refetch={refetchComments}
          setFeedCommentCounter={setFeedCommentCounter}
          replySize={initReplySizes[ii]}
          onClickOnReply={onClickOnReply}
        />
      ))}
      {loading && (
        <Flex className={classes.moreComments}>
          <Spinner className={classes.spinner} />
        </Flex>
      )}
      {!loading && hasNextPage && (
        <Flex className={classes.moreComments}>
          <Divider className={classes.divider} />
          <Button
            rightIcon="chevron-down"
            schema="transparent"
            onClick={handleMoreClicked}
            className={classes.seeAllButton}
            rightColor="primaryText"
          >
            <Typography color="primaryText" height={18} size={14}>
              {t('view_m_comments')}
            </Typography>
          </Button>
          <Divider className={classes.divider} />
        </Flex>
      )}
    </Flex>
  );
};

export default CommentsList;
