import { routeNames } from '@lobox/utils';
import { useLocation /*, useParams*/ } from '@lobox/utils';

interface UseUrlRouteResult {
  isHomePage: boolean;
  isProfileFeedTab: boolean;
}

const useUrlRoute = (): UseUrlRouteResult => {
  const location = useLocation();
  const { pathname } = location;
  const isHomePage = pathname === routeNames.home;
  const isProfileFeedTab = pathname.includes(routeNames.feed);

  return {
    isHomePage,
    isProfileFeedTab,
  };
};

export default useUrlRoute;
