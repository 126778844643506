import React from 'react';
import dynamic from 'next/dynamic';
import { useDisclosure, useTranslation } from '@lobox/utils';
import Divider from '@lobox/uikit/Divider/DividerVertical';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import cnj from '@lobox/uikit/utils/cnj';
import useFeedElement from '../../Context/useFeedElement';
import ReactionInfo from './ReactionInfo.component';
import classes from './Reactions.component.module.scss';
import BaseButton from '@shared/uikit/Button/BaseButton';
import DividerCmp from '../../Components/Divider';

const ReactionsModal = dynamic(() => import('../../modals/PostReactions'));

const Reactions = ({ visibleDivider }, ref: any): JSX.Element => {
  const { isOpen, onToggle } = useDisclosure();
  const { t } = useTranslation();
  const {
    toggleComments,
    post,
    reactionsCount,
    reactions,
    postWithoutAnyReactions,
    variant,
  } = useFeedElement();

  const isLightBox = variant === 'light-box';
  const commentCounter = Number(post?.commentCounter || 0);

  const handleReactionsClicked = () => {
    // if (isTabletAndLess) {
    //   toggleComments();
    // } else {
    //   onToggle();
    // }
    onToggle();
  };

  if (postWithoutAnyReactions && !commentCounter) return null;

  return (
    <>
      <Flex
        flexDir="row"
        className={cnj(
          classes.container,
          variant === 'search-view' && classes.paddingTopZero
        )}
        ref={ref}
      >
        <Flex flexDir="row" className={classes.reactions}>
          {reactionsCount ? (
            <>
              {reactions.map((x, i) => {
                const reactionType: string = {
                  like: 'LIKE',
                  boost: 'BOOST',
                  celebrate: 'CELEBRATE',
                  disLike: 'DISLIKE',
                  notRelevant: 'NOT_RELEVANT',
                }[x.key as string];

                if (x?.value > 0) {
                  return (
                    <ReactionInfo
                      key={x?.key}
                      postId={post?.id}
                      type={reactionType}
                      title={t(reactionType?.toLowerCase())}
                    >
                      <Flex style={{ zIndex: -i }}>
                        <x.Render
                          className={classes.reactionIcons}
                          onClick={handleReactionsClicked}
                          hasHover={false}
                          size="small"
                        />
                      </Flex>
                    </ReactionInfo>
                  );
                }

                return null;
              })}

              <Divider height={24} className={classes.divider} />

              <BaseButton onClick={handleReactionsClicked}>
                <ReactionInfo postId={post.id}>
                  <div className={classes.countReaction}>
                    <Typography
                      font="400"
                      height={18}
                      color="border"
                      size={14}
                      className={classes.text}
                    >
                      {reactionsCount}
                    </Typography>
                  </div>
                </ReactionInfo>
              </BaseButton>
            </>
          ) : null}
        </Flex>
        {commentCounter > 0 ? (
          <Typography
            size={14}
            height={18}
            font="400"
            color="border"
            className={cnj(
              classes.comments,
              isLightBox && classes.comments_lightBox
            )}
            onClick={toggleComments}
          >
            {commentCounter} {commentCounter > 1 ? t('comments') : t('comment')}
          </Typography>
        ) : null}
      </Flex>
      {isOpen && (
        <ReactionsModal
          onClose={onToggle}
          post={post}
          reactions={reactions}
          allReactionsCount={reactionsCount}
        />
      )}
      {visibleDivider && <DividerCmp />}
    </>
  );
};

export default React.forwardRef(Reactions);
