import { getPostOrCommentReactions } from '@lobox/utils';

import { useMutation } from '@tanstack/react-query';
import type { UseMutationResult } from '@tanstack/react-query';

interface ReactionsProps {
  type?: string;
  commentId?: string;
  postId?: string;
}

const useGetPostCommentReactionsInfo = (
  params: ReactionsProps
): UseMutationResult<any> =>
  useMutation(() => getPostOrCommentReactions({ ...params, size: 5 }));

export default useGetPostCommentReactionsInfo;
